import { Application } from '@bwinsurance/meta-rater-types';
import { ReactNode } from 'react';
import If from '../If';
import HomeInfo from '../HomeInfo';
import AutoInfo from '../AutoInfo';
import PriorPolicyInfo from '../PriorPolicyInfo';

export const ApplicationDisplay = ({
  application,
}: {
  application?: Application | null;
}): ReactNode => {
  if (!application) {
    return null;
  }

  return (
    <>
      <If condition={application?.insuranceProduct === 'Home'}>
        <HomeInfo application={application} />
      </If>

      <If condition={application?.insuranceProduct === 'Auto'}>
        <AutoInfo application={application} />
      </If>

      <If condition={Object.keys(application?.priorPolicy ?? {}).length > 0}>
        <PriorPolicyInfo applications={[application]} />
      </If>
    </>
  );
};
