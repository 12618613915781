import styles from './dialog.module.css';
import '../../theme.module.css';
import { useEffect, useRef, useState } from 'react';
import { debounce } from '@/controllers/common/hooks/utility/utils';

/**
 * Displays a greyed out backdrop and dialog container.
 * 
 * @param {Object} config - The configuration object for this component.
 * @param {HTML} config.children - The markup to display in the dialog.
 * @param {boolean} config.open - Flag indicating whether the dialog is displayed.
 * @param {string} [config.theme] - Indicates which style theme to apply: bw or crm.
 * 
 * @example
 *  <Dialog open={open}>
        ...
        dialog contents
        ...
    </Dialog>
 */
export default function Dialog({
  children,
  open,
  theme = 'bw',
  width = 475,
}: {
  children: any;
  open: boolean;
  theme?: 'bw' | 'crm';
  width?: number;
}) {
  const [dialogHeight, setDialogHeight] = useState<string>('fit-content');
  const [shouldOverflow, setShouldOverflow] = useState<boolean>(false);
  const dialogRef: { current: any } = useRef(null);

  // this sets the height of the dialog
  // to make sure it adds scrollbar for small screen
  useEffect(() => {
    const updateDialogHeight = debounce(() => {
      if (dialogRef.current) {
        const rect = dialogRef.current.getBoundingClientRect();
        if (window.innerHeight <= 575) {
          if (rect.height >= window.innerHeight * 0.9) {
            setDialogHeight('85%');
            setShouldOverflow(true);
          }
        } else {
          setDialogHeight('fit-content');
          setShouldOverflow(false);
        }
      }
    });
    if (open) {
      window.addEventListener('resize', updateDialogHeight);
      updateDialogHeight();
    }
    return () => window.removeEventListener('resize', updateDialogHeight);
  }, [open]);

  if (!open) {
    return;
  }

  return (
    <div className={[styles[theme], styles.overlay].join(' ')}>
      <div
        className={styles.dialog}
        style={{ width: `${width}px`, height: `${dialogHeight}` }}
        ref={dialogRef}
      >
        <div
          className={styles.content}
          style={shouldOverflow ? { overflowY: 'auto' } : {}}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
