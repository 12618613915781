import { useAuthenticatedFetch as _useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { getAccessToken as _getAccessToken } from '@/controllers/common/utils';
import { callCrmWhoAmI as _callCrmWhoAmI } from '@/pages/profile';
import { useMsal as _useMsal } from '@azure/msal-react';
import {
  getAccountDetails as _getAccountDetails,
  getAssociatedContactList as _getAssociatedContactList,
  getFormList as _getFormList,
  getPolicyList as _getPolicyList,
  getForm,
  getSubmission,
} from '@/services/forms/formsService';
import SubmissionView from './SubmissionView';
import { useEffect, useState } from 'react';
import { Submission } from '@/models/forms/submission';
import { APIForm } from '@bwinsurance/shared-components';

interface InjectedDependencies {
  useMsal?: typeof _useMsal;
  useAuthenticatedFetch?: typeof _useAuthenticatedFetch;
  getAccessToken?: typeof _getAccessToken;
  callCrmWhoAmI?: typeof _callCrmWhoAmI;
}

interface Props {
  submissionId: string;
  injectedDependencies?: InjectedDependencies;
}

const SubmissionController: React.FC<Props> = ({
  submissionId,
  injectedDependencies,
}) => {
  const {
    useMsal = _useMsal,
    useAuthenticatedFetch = _useAuthenticatedFetch,
    getAccessToken = _getAccessToken,
    callCrmWhoAmI = _callCrmWhoAmI,
  } = injectedDependencies ?? {};

  const { instance } = useMsal();
  const authedFetch = useAuthenticatedFetch();

  const [submissionData, setSubmissionData] = useState<Submission>();
  const [form, setForm] = useState<APIForm>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (submissionId) {
      getSubmissionData();
    }
  }, [submissionId]);

  const getSubmissionData = async () => {
    try {
      const submission = await getSubmission(submissionId, authedFetch);
      setSubmissionData(submission);

      const form = await getForm(submission.formKey, authedFetch);
      setForm(form);
    } catch (error) {
      setErrorMessage('Error loading submission.');
    }
  };

  return (
    <SubmissionView
      submissionData={submissionData}
      form={form}
      errorMessage={errorMessage}
    />
  );
};

export default SubmissionController;
