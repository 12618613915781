import styles from './checkbox.module.css';
import '../../theme.module.css';
import Icon from '../Icon';

/**
 * Displays a customizable styled checkbox.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {string} [config.label] - Text to display next to the checkbox.
 * @param {boolean} config.checked - Flag indicating whether the checkbox is checked or not.
 * @param {function} [config.onChange] - Function called whenever the checkbox state changes.
 * @param {boolean} [config.disabled] - Flag indicating whether the checkbox is disabled or not.
 * @param {string} [config.theme] - Indicates which style theme to apply: bw or crm.
 *
 * @example
 * <Checkbox checked={document.selected} onChange={() => toggleSelection(document.id, !document.selected)} />
 */
export default function Checkbox({
  label,
  checked,
  onChange,
  disabled,
  type,
  classes,
  theme = 'bw',
}: {
  label?: string;
  checked: boolean;
  onChange?: React.DOMAttributes<HTMLLabelElement>['onClick'];
  disabled?: boolean;
  type?: string;
  classes?: string;
  theme?: 'bw' | 'crm';
}) {
  const labelPrefix = label ? `${label}-` : '';
  const disabledSuffix = disabled ? '-disabled' : '';
  return (
    <label
      className={[
        styles[theme],
        styles.container,
        disabled ? styles.disabled : '',
      ].join(' ')}
      onClick={(event) => {
        if (disabled) {
          return;
        } else if (onChange) {
          onChange(event);
        }
      }}
    >
      {!checked && (
        <div
          className={[
            styles.unchecked,
            type === 'radio' ? styles.radio : styles.square,
          ].join(' ')}
          data-testid={`${labelPrefix}unchecked${disabledSuffix}`}
        ></div>
      )}
      {checked && (
        <div
          className={[
            styles.checked,
            type === 'radio' ? styles.radio : styles.square,
          ].join(' ')}
          data-testid={`${labelPrefix}checked${disabledSuffix}`}
        >
          <Icon
            type={'checkmark'}
            color={'white'}
            size={theme === 'bw' ? 18 : 14}
          />
        </div>
      )}
      {label && (
        <span className={[styles.label, classes].join(' ')}>{label}</span>
      )}
    </label>
  );
}
