import type { FormPage, FormSection } from '../models/formTypes';
import { applicant } from './applicant';
import { coApplicant } from './coApplicant';

export const applicants: FormPage = {
  id: 'applicants',
  display: 'Applicants',
  icon: 'people',
  elements: [
    {
      display: 'Main Applicant',
      id: 'mainApplicant',
      type: 'panel',
      icon: 'person',
      elements: [...(applicant.elements as FormSection[])],
    },
    {
      display: 'Co-Applicant',
      id: 'coApplicant',
      type: 'panel',
      icon: 'person',
      elements: [...(coApplicant.elements as FormSection[])],
    },
  ],
};
