import {
  InsuranceProduct,
  InsuranceProductEnum,
  Raters,
  RatersEnum,
} from '@bwinsurance/meta-rater-types';
import { QuoteGroupWithQuote } from '../models/quoteTypes';
import {
  INSURANCE_PRODUCT_TO_CRM_PRODUCT,
  RaterProductEnabled,
} from '../models/rateProductTypes';
import { getAgent } from '@/services/opportunity/network/dynamics';

export const findQuoteGroups = ({
  raterProductToSend,
  quoteGroups,
}: {
  raterProductToSend: RaterProductEnabled[];
  quoteGroups: QuoteGroupWithQuote[];
}) => {
  const successQuoteGroups: QuoteGroupWithQuote[] = [];
  const errorQuoteGroups: QuoteGroupWithQuote[] = [];
  raterProductToSend.forEach((raterProduct) => {
    const group = quoteGroups.find(
      (quoteGroup) =>
        quoteGroup.rater === raterProduct.rater &&
        quoteGroup.insuranceProduct === raterProduct.product
    );
    if (group?.raterData) {
      successQuoteGroups.push(group);
    }
    if (group?.errors) {
      errorQuoteGroups.push(group);
    }
  });

  return { successQuoteGroups, errorQuoteGroups };
};

export const findRaterQuoteGroup = (
  quoteGroups: QuoteGroupWithQuote[],
  rater: Raters,
  product?: InsuranceProduct
) => {
  return quoteGroups.find(
    (quoteGroup) =>
      quoteGroup.rater === rater &&
      (!product || quoteGroup.insuranceProduct === product)
  );
};

export const findLatestQuoteGroup = (quoteArray: QuoteGroupWithQuote[]) => {
  return quoteArray.length
    ? quoteArray.reduce((latest, current) =>
        current.updated > latest.updated ? current : latest
      )
    : undefined;
};

export const getSuccessRaterQuoteGroups = (
  quoteGroups: QuoteGroupWithQuote[]
) => {
  const successRaterQuoteGroups: QuoteGroupWithQuote[] = [];
  const allSuccessEZ: {
    Home: QuoteGroupWithQuote[];
    Auto: QuoteGroupWithQuote[];
  } = {
    Home: [],
    Auto: [],
  };

  const allSuccessQuoteGroups = quoteGroups.filter(
    (quoteGroup) => quoteGroup.status === 'Completed' && quoteGroup.raterData
  );

  allSuccessQuoteGroups.forEach((quoteGroup) => {
    if (quoteGroup.rater === RatersEnum.enum.EZLynx) {
      if (quoteGroup.insuranceProduct === InsuranceProductEnum.enum.Home) {
        allSuccessEZ.Home.push(quoteGroup);
      }
      if (quoteGroup.insuranceProduct === InsuranceProductEnum.enum.Auto) {
        allSuccessEZ.Auto.push(quoteGroup);
      }
    }
  });

  const latestSuccessEZHome = findLatestQuoteGroup(allSuccessEZ.Home);
  const latestSuccessEZAuto = findLatestQuoteGroup(allSuccessEZ.Auto);
  // currently, we only allow one successful submission to QuoteRush
  // there should be at most one successful QuoteRush quote group, if it exists
  const successQR = findRaterQuoteGroup(
    allSuccessQuoteGroups,
    RatersEnum.enum.QuoteRush
  );

  if (successQR) {
    successRaterQuoteGroups.push(successQR);
  }
  if (latestSuccessEZHome) {
    successRaterQuoteGroups.push(latestSuccessEZHome);
  }
  if (latestSuccessEZAuto) {
    successRaterQuoteGroups.push(latestSuccessEZAuto);
  }

  return successRaterQuoteGroups;
};

export const getSuccessMessage = (quoteGroups: QuoteGroupWithQuote[]) => {
  const isQRQuoteGroup = findRaterQuoteGroup(
    quoteGroups,
    RatersEnum.enum.QuoteRush
  );
  const isEZHomeQuoteGroup = findRaterQuoteGroup(
    quoteGroups,
    RatersEnum.enum.EZLynx,
    InsuranceProductEnum.enum.Home
  );
  const isEZAutoQuoteGroup = findRaterQuoteGroup(
    quoteGroups,
    RatersEnum.enum.EZLynx,
    InsuranceProductEnum.enum.Auto
  );

  const crmHomeDisplay =
    INSURANCE_PRODUCT_TO_CRM_PRODUCT[InsuranceProductEnum.enum.Home];
  const crmAutoDisplay =
    INSURANCE_PRODUCT_TO_CRM_PRODUCT[InsuranceProductEnum.enum.Auto];

  let message = '';
  if (isQRQuoteGroup) {
    const messageForOR = isQRQuoteGroup.raterData?.leadId
      ? `QuoteRUSH (Lead ID: ${isQRQuoteGroup.raterData.leadId})`
      : 'QuoteRUSH';

    // QR home only - Homeowners sent to QR (Lead ID)
    if (!isEZHomeQuoteGroup && !isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${messageForOR}.`;
    }
    // QR & EZ home only - Homeowners sent to QR (Lead ID ) & EZ
    if (isEZHomeQuoteGroup && !isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${messageForOR} and ${RatersEnum.enum.EZLynx}.`;
    }
    // QR home & EZ auto - Homeowners sent to QR (Lead ID) and Private Passenger Auto Sent to EZ
    if (!isEZHomeQuoteGroup && isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${messageForOR} and ${crmAutoDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
    // QR & EZ home & EZ auto - Homeowners sent to QR (Lead ID ) & EZ and Private Passenger Auto Sent to EZ
    if (isEZHomeQuoteGroup && isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${messageForOR} and ${RatersEnum.enum.EZLynx} and ${crmAutoDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
  }

  if (!isQRQuoteGroup) {
    // EZ home only - Homeowner sent to EZ
    if (isEZHomeQuoteGroup && !isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
    // EZ auto only - Private Passenger Auto Sent to EZ
    if (!isEZHomeQuoteGroup && isEZAutoQuoteGroup) {
      message = `${crmAutoDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
    // EZ home & auto only - Homeowners & Private Passenger Auto sent to EZ
    if (isEZHomeQuoteGroup && isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} and ${crmAutoDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
  }

  return message;
};

export const getErrorMessages = (quoteGroups: QuoteGroupWithQuote[]) => {
  const failedGroups = quoteGroups.map((quoteGroup) => {
    const raterName =
      quoteGroup.rater === RatersEnum.enum.QuoteRush
        ? 'QuoteRUSH'
        : quoteGroup.rater;
    return {
      rater: raterName,
      product: quoteGroup.insuranceProduct,
      errorName: quoteGroup.errors?.[0]?.name,
      errorMessage: quoteGroup.errors?.[0]?.message,
    };
  });

  const uniqueErrorNames = Array.from(
    new Set(failedGroups.map((group) => group.errorName as ErrorType))
  );

  const messages = uniqueErrorNames.map((errorName) => {
    const matchedGroups = failedGroups.filter(
      (group) => group.errorName === errorName
    );
    const raterNames = Array.from(
      new Set(matchedGroups.map((group) => group.rater))
    );
    const failureMessage = matchedGroups
      .map((group) => group.errorMessage)
      .join(' ');
    return getErrorMessage(errorName, {
      quoteGroupNames: raterNames,
      failureMessage: failureMessage,
    });
  });

  return messages;
};

export type ErrorType =
  | 'AuthenticationError'
  | 'ValidationError'
  | 'UnhandledError'
  | 'AlreadySentError'
  | 'InvalidFields';

export const getErrorMessage = (
  errorName?: ErrorType,
  options: {
    quoteGroupNames?: string[];
    failureMessage?: string;
    leadId?: string;
  } = {}
) => {
  const { leadId, quoteGroupNames, failureMessage } = options;
  const leadIdString = leadId ? ` (Lead ID: ${leadId})` : '';
  const nameString = quoteGroupNames?.join(' and ') || '';

  switch (errorName) {
    case 'AuthenticationError':
      return `The login credentials we have for ${nameString} are incorrect. Please contact Agency Support.`;
    case 'UnhandledError':
      return quoteGroupNames && quoteGroupNames.length > 1
        ? `${nameString} are having technical difficulties. Try again.`
        : `${nameString} is having technical difficulties. Try again.`;
    case 'ValidationError':
      return failureMessage || 'Something went wrong. Please try again!';
    case 'AlreadySentError':
      return `You've already sent Homeowners. Please make adjustments directly in ${nameString}${leadIdString}.`;
    case 'InvalidFields':
      return 'Some fields are invalid. Please correct the errors and try again.';
    default:
      return 'Something went wrong. Please try again!';
  }
};

export const getRaterButtonErrorMessage = async (
  accessToken: string,
  agentId?: string,
  userId?: string
): Promise<string> => {
  if (!agentId) {
    return 'Please assign an agent to this opportunity and refresh the page in order to send data to a rater.';
  }

  if (agentId !== userId) {
    const agent = await getAgent(agentId, accessToken);
    const agentName = agent?.fullname ?? 'The agent assigned';
    return `${agentName} hasn't been set up to send data to a rater. Please contact Agency Support.`;
  }

  return 'You haven\'t been set up to send data to a rater. Please contact Agency Support.';
};

export const flatten = (obj: object) => {
  const result: Record<string, string> = {};
  for (const key of Object.keys(obj)) {
    const item = obj[key as keyof object];
    if (typeof item === 'object' && item !== null) {
      const nested = flatten(item);
      for (const nestedKey of Object.keys(nested)) {
        result[`${key}.${nestedKey}`] = nested[nestedKey];
      }
    } else {
      result[key] = item;
    }
  }
  return result;
};
