import { FC } from 'react';

export type OverlapProps = {
  showOverlay: boolean;
  message: string;
};

const Overlay: FC<OverlapProps> = ({ showOverlay, message }) => {
  if (!showOverlay) {
    return null;
  }
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 gap-2">
      <span className="material-symbols-outlined text-[20px] animate-spin text-light-text-inverse">
        progress_activity
      </span>
      <span className="text-light-text-inverse text-lg font-semibold">
        {message}
      </span>
    </div>
  );
};

export { Overlay };
