import type { FC } from 'react';
import { Button } from '@/components/ui/button';

export type SendToRaterButtonProps = {
  toggleRaterDialog: () => void;
};

const SendToRaterButton: FC<SendToRaterButtonProps> = ({
  toggleRaterDialog,
}) => {
  return (
    <Button
      data-testid={'send-to-rater-button'}
      variant={'secondary'}
      size={'md'}
      onClick={toggleRaterDialog}
    >
      <span className="material-symbols-outlined text-base">send</span>
      <span>Send to rater(s)</span>
    </Button>
  );
};

export { SendToRaterButton };
