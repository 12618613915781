import { useState } from 'react';
import Button from '../shared/button';
import VerticalDivider from '../shared/verticalDivider';
import Email from '@/components/documents/dialogs/email';
import Rename from '@/components/documents/dialogs/rename';
import Edit from '@/components/documents/dialogs/edit';
import Recategorize from '@/components/documents/dialogs/recategorize';
import Link from '@/components/documents/dialogs/link';
import { Document, DocumentFieldType } from '../../models/document/document';
import { Customer } from '../../models/document/customer';
import { Policy } from '../../models/document/policy';
import { Contact } from '../../models/document/contact';
import Tooltip from '../shared/tooltip';
import { documentConfig } from '@/services/common/config';
import styles from './actionBar.module.css';
import '../../theme.module.css';
import { Opportunity } from '../../models/document/opportunity';

const dialogTypes = {
  EMAIL: 'email',
  RENAME: 'rename',
  EDIT_NOTE: 'note',
  RE_CATEGORIZE: 'categorize',
  CHANGE_LINKS: 'links',
};

interface ActionBarProps {
  customer: Customer;
  userId: string;
  userName: string;
  selectedDocuments: Document[];
  showAlertToast: any;
  policyList: Policy[];
  opportunityList: Opportunity[];
  categoryList: string[];
  associatedContactList: Contact[];
  deselectAll: () => void;
  refreshData: () => void;
  updateField: (
    documentId: string,
    field: DocumentFieldType,
    value: any
  ) => void;
}

export default function ActionBar({
  customer,
  userId,
  userName,
  selectedDocuments,
  showAlertToast,
  policyList,
  opportunityList,
  categoryList,
  associatedContactList,
  deselectAll,
  refreshData,
  updateField,
}: ActionBarProps) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');

  const showDialog = (type: string) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const getSelectedDocumentListSize = () => {
    return selectedDocuments
      .map((document: Document) => document.size)
      .reduce((result: number, value: number) => result + value, 0);
  };

  const anyDocumentMissingCategory = () => {
    return selectedDocuments
      .map((document: Document) => document.documentType === '')
      .reduce((result: boolean, value: boolean) => result || value, false);
  };

  const getEmailTooltipMessage = () => {
    if (anyDocumentMissingCategory()) {
      return `Before emailing, please categorize: 
${selectedDocuments
  .filter((document: Document) => document.documentType === '')
  .map((document: Document) => ' •  ' + document.fileOriginalName)
  .join('\n')}`;
    } else if (
      getSelectedDocumentListSize() > parseInt(documentConfig.maxEmailSize)
    ) {
      return `We unfortunately can't send that many attachments. 
Please de-select some and try again.`;
    } else {
      return '';
    }
  };

  const getDialogContent = () => {
    switch (dialogType) {
      case dialogTypes.EMAIL:
        return (
          <Email
            customer={customer}
            selectedDocumentList={selectedDocuments}
            associatedContactList={associatedContactList}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
          />
        );
      case dialogTypes.RENAME:
        return (
          <Rename
            selectedDocument={selectedDocuments[0]}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            updateField={updateField}
          />
        );
      case dialogTypes.EDIT_NOTE:
        return (
          <Edit
            selectedDocumentList={selectedDocuments}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            updateField={updateField}
          />
        );
      case dialogTypes.RE_CATEGORIZE:
        return (
          <Recategorize
            selectedDocumentList={selectedDocuments}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            category={selectedDocuments[0].documentType}
            categoryList={categoryList}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            updateField={updateField}
          />
        );
      case dialogTypes.CHANGE_LINKS:
        return (
          <Link
            selectedDocumentList={selectedDocuments}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            linkedPolicyList={selectedDocuments[0]?.links?.policy ?? []}
            allPolicyList={policyList}
            linkedOpportunityList={
              selectedDocuments[0]?.links?.opportunity ?? []
            }
            allOpportunityList={opportunityList}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            userId={userId}
            userName={userName}
            updateField={updateField}
          />
        );
      default:
        return;
    }
  };

  if (selectedDocuments.length === 0) {
    return;
  }

  return (
    <>
      <div className={styles.actionBar}>
        <div className={styles.selectedCount}>{`${
          selectedDocuments.length > 0 ? selectedDocuments.length : 'No'
        } ${selectedDocuments.length !== 1 ? 'files' : 'file'} selected`}</div>
        <VerticalDivider />
        <div>
          <Tooltip
            message={getEmailTooltipMessage()}
            disabled={
              !(
                getSelectedDocumentListSize() >
                  parseInt(documentConfig.maxEmailSize) ||
                anyDocumentMissingCategory()
              )
            }
            theme={'crm'}
          >
            <Button
              callback={() => showDialog(dialogTypes.EMAIL)}
              type={'text'}
              theme={'crm'}
              icon={'mail'}
              iconSize={18}
              disabled={
                getSelectedDocumentListSize() >
                  parseInt(documentConfig.maxEmailSize) ||
                anyDocumentMissingCategory()
              }
            >
              Email
            </Button>
          </Tooltip>
        </div>
        <VerticalDivider />
        <div className={styles.buttonContainer}>
          <Button
            callback={() => showDialog(dialogTypes.RENAME)}
            type={'text'}
            theme={'crm'}
            icon={'edit'}
            disabled={selectedDocuments.length !== 1}
          >
            Rename
          </Button>
          <Button
            callback={() => showDialog(dialogTypes.EDIT_NOTE)}
            type={'text'}
            theme={'crm'}
            icon={'note'}
            disabled={selectedDocuments.length === 0}
          >
            Edit note
          </Button>
          <Button
            callback={() => showDialog(dialogTypes.RE_CATEGORIZE)}
            type={'text'}
            theme={'crm'}
            icon={'tag'}
            disabled={selectedDocuments.length === 0}
          >
            Change category
          </Button>
          <Button
            callback={() => showDialog(dialogTypes.CHANGE_LINKS)}
            type={'text'}
            theme={'crm'}
            icon={'link'}
            disabled={selectedDocuments.length === 0}
          >
            Link to...
          </Button>
        </div>
      </div>
      {getDialogContent()}
    </>
  );
}
