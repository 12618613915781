import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@/components/shared/button';
import Dialog from '@/components/shared/dialog';
import TextField from '@/components/shared/textField';
import Icon from '@/components/Icon';
import { Select } from '@/components/shared/select';
import { Policy } from '@/models/document/policy';
import { Opportunity } from '@/models/document/opportunity';
import SectionDivider from '@/components/shared/sectionDivider';
import { addFileToFusion } from '@/services/document/documentService';
import { UploadData } from '@/models/document/uploadData';
import { ListOption } from '@/models/common/options';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import {
  buildCategorySelectHandler,
  buildOpportunitySelectHandler,
  buildPolicySelectHandler,
  getCleanFileName,
  getMappedCategories,
  getMappedOpportunities,
  getMappedPolicies,
  resetOptionListState,
} from '@/controllers/document/DocumentDialogController';
import styles from './upload.module.css';
import '@/theme.module.css';

export interface UploadProps {
  open: boolean;
  closeDialog: any;
  files?: FileList;
  categoryList: string[];
  accountId: string;
  policyList: Policy[];
  opportunityList: Opportunity[];
  showAlertToast: (
    type: string,
    message: string,
    options?: { allowDismiss: boolean }
  ) => void;
  refreshData: () => void;
  pca: IPublicClientApplication;
  userName: string;
  linkedOppId?: string;
}

export default function Upload({
  open,
  closeDialog,
  files = {
    item: () => null,
    length: NaN,
    [Symbol.iterator]() {
      return this;
    },
  } as FileList,
  categoryList,
  accountId,
  policyList,
  opportunityList,
  showAlertToast,
  refreshData,
  pca,
  userName,
  linkedOppId,
}: UploadProps) {
  const fileInputRef: { current: any } = useRef<HTMLInputElement>(null);
  const [fileDisplayName, setFileDisplayName] = useState<string>('');

  const [category, setCategory] = useState<string>('');
  const [categoryOptionList, setCategoryOptionList] = useState<ListOption[]>(
    []
  );

  const [notes, setNotes] = useState<string>('');

  const [selectedOpportunities, setSelectedOpportunities] = useState<string[]>(
    []
  );
  const [opportunityOptionList, setOpportunityOptionList] = useState<
    ListOption[]
  >([]);

  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
  const [policyOptionList, setPolicyOptionList] = useState<ListOption[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const authedFetch = useAuthenticatedFetch();

  // form input handlers
  const handleFileDisplayNameChange = useCallback(
    (value: string) => {
      const cleanValue = getCleanFileName(value);

      // alert user that special characters are being removed
      if (value && value !== cleanValue) {
        showAlertToast(
          'info',
          `Detected invalid characters in file name, changed from: "${value}" to: "${cleanValue}".`
        );
      }

      setFileDisplayName(cleanValue);
    },
    [setFileDisplayName, showAlertToast]
  );

  const handleOpportunitySelect = buildOpportunitySelectHandler(
    opportunityOptionList,
    ({
      opportunityOptionList: opportunityOptionListCopy,
      selectedOpportunityIds,
    }: {
      opportunityOptionList: ListOption[];
      selectedOpportunityIds: string[];
    }) => {
      setOpportunityOptionList(opportunityOptionListCopy);
      setSelectedOpportunities(selectedOpportunityIds);
    }
  );

  const handlePolicySelect = buildPolicySelectHandler(
    policyOptionList,
    ({
      policyOptionList: policyOptionListCopy,
      selectedPolicyIds,
    }: {
      policyOptionList: ListOption[];
      selectedPolicyIds: string[];
    }) => {
      setPolicyOptionList(policyOptionListCopy);
      setSelectedPolicies(selectedPolicyIds);
    }
  );

  const handleCategorySelect = buildCategorySelectHandler(
    categoryOptionList,
    ({
      category,
      categoryOptionList: categoryOptionListCopy,
    }: {
      category: string;
      categoryOptionList: ListOption[];
    }) => {
      setCategory(category);
      setCategoryOptionList(categoryOptionListCopy);
    }
  );

  const save = () => {
    const user = pca.getActiveAccount();

    if (!user) {
      showAlertToast('warning', 'User not logged in.');
      return;
    }
    const data: UploadData = {
      file: fileInputRef.current.files[0],
      fileName: fileDisplayName,
      category,
      notes,
      policyList: selectedPolicies,
      opportunityList: linkedOppId ? [linkedOppId] : selectedOpportunities,
      accountId,
      userId: user.homeAccountId.split('.')[0],
      userName: userName,
    };

    setLoading(true);
    addFileToFusion(data, authedFetch)
      .then((result: string) => {
        resetThenClose();
        showAlertToast('success', `Added "${result}"`);
        refreshData();
      })
      .catch((error: string) => {
        showAlertToast('warning', 'Error adding file.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetThenClose = () => {
    setSelectedOpportunities([]);
    setOpportunityOptionList(resetOptionListState);

    setSelectedPolicies([]);
    setPolicyOptionList(resetOptionListState);

    setCategory('');
    setCategoryOptionList(resetOptionListState);

    setNotes('');
    setFileDisplayName('');

    closeDialog();
  };

  // hooks
  useEffect(() => {
    const mappedValues = getMappedOpportunities(opportunityList);
    setOpportunityOptionList(mappedValues);
  }, [opportunityList]);

  useEffect(() => {
    const mappedValues = getMappedPolicies(policyList);
    setPolicyOptionList(mappedValues);
  }, [policyList]);

  useEffect(() => {
    const mappedValues = getMappedCategories(categoryList);
    setCategoryOptionList(mappedValues);
  }, [categoryList]);

  useEffect(() => {
    if (fileDisplayName) {
      return;
    }
    if (
      files?.length &&
      fileInputRef?.current &&
      fileInputRef.current.files !== files
    ) {
      fileInputRef.current.files = files;
      handleFileDisplayNameChange(fileInputRef.current.files[0].name);
    }
    // This doesn't need to run every time fileDisplayName changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, handleFileDisplayNameChange]);

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Upload a document</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <SectionDivider title={'Properties'} theme={'crm'} />
      <div className={styles.inputContainer}>
        <label>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*, video/*, .doc, .docx, .pdf, .xml, .txt, .csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <TextField
            label={'Document name'}
            theme={'crm'}
            value={fileDisplayName}
            onChange={handleFileDisplayNameChange}
            required={true}
          />
        </label>
        <Select
          label={'Category'}
          theme={'crm'}
          options={categoryOptionList}
          required={true}
          placeholder={'Select a category...'}
          updateValue={handleCategorySelect}
        />
        <TextField
          label={'Note'}
          theme={'crm'}
          value={notes}
          onChange={(value: string) => setNotes(value)}
          placeholder={'Add additional details here...'}
        />
      </div>
      {!linkedOppId && (
        <>
          <SectionDivider title={'Link to '} theme={'crm'} />
          <div className={styles.inputContainer}>
            <Select
              label={'Policy'}
              theme={'crm'}
              options={policyOptionList}
              placeholder={'Select an associated policy...'}
              updateValue={handlePolicySelect}
              multiple={true}
            />
            <Select
              label={'Opportunity'}
              theme={'crm'}
              options={opportunityOptionList}
              placeholder={'Select an associated opportunity...'}
              updateValue={handleOpportunitySelect}
              multiple={true}
            />
          </div>
        </>
      )}
      <div className={styles.buttonContainer}>
        <Button
          callback={save}
          type={'primary'}
          theme={'crm'}
          disabled={!fileDisplayName || !category}
          loading={loading}
        >
          Add document
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
