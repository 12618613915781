import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Document } from '@/models/document/document';
import styles from './index.module.css';
import UploadOverlay from '@/components/documents/uploadOverlay';
import Upload from '@/components/documents/dialogs/upload';
import { useMsal } from '@azure/msal-react';
import AlertToast, {
  AlertToast as AlertToastInterface,
} from '@/components/shared/alertToast';
import { Opportunity } from '@/models/document/opportunity';
import DocumentTable from '@/components/documents/DocumentTable/documentTable';
import { Customer } from '@/models/document/customer';
import { Contact } from '@/models/document/contact';
import ActionBar from '@/components/documents/actionBar';
import { Policy } from '@/models/document/policy';
import ControlBar from '@/components/documents/controlBar';
import { useLocation } from 'react-router-dom';
import { TableTextInterface } from '@/components/shared/table';
import { validateFile } from '@/controllers/document/utils';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const applySearchFilters = (
  documents: Document[] | undefined,
  search: string
) => {
  if (!search) {
    return documents;
  }
  return documents?.filter((document: Document) => {
    const loweredSearch = search?.toLowerCase();

    return (
      document.createdAt?.includes(loweredSearch) ||
      document.fileOriginalName?.toLowerCase()?.includes(loweredSearch) ||
      document.documentType?.toLowerCase()?.includes(loweredSearch) ||
      document.createdBy?.name?.toLowerCase()?.includes(loweredSearch) ||
      document.notes?.toLowerCase()?.includes(loweredSearch) ||
      document.id.toLowerCase().includes(loweredSearch)
    );
  });
};

export type DocumentsForOpportunityProps = {
  documents: Document[];
  setDocuments: Dispatch<SetStateAction<Document[]>>;
  refreshData: () => void;
  userName: string;
  customer: Customer;
  contactList: Contact[];
  categoryList: string[];
  policyList: Policy[];
  opportunityList: Opportunity[];
  accountId: string;
  opportunityId: string;
  tableText: TableTextInterface;
};

const DocumentsForOpportunity: FC<DocumentsForOpportunityProps> = ({
  documents,
  setDocuments,
  refreshData,
  userName,
  customer,
  contactList,
  categoryList,
  policyList,
  opportunityList,
  accountId,
  opportunityId,
  tableText,
}) => {
  const queryParams = useQuery();
  const { instance } = useMsal();
  const [uploadOverlayOpen, setUploadOverlayOpen] = useState<boolean>(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<any>();

  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>();
  const [search, setSearch] = useState<string>(queryParams.get('search') || '');

  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);

  const [alertToastOpen, setAlertToastOpen] = useState<boolean>(false);
  const [alertToast, setAlertToast] = useState<AlertToastInterface>({
    text: '',
    type: 'info',
  });

  useEffect(() => {
    if (documents?.length) {
      setFilteredDocuments(applySearchFilters(documents, search));
    }
  }, [search, documents]);

  const dropHandler = (event: any) => {
    event.preventDefault();

    const selectedFile = event.dataTransfer.files;
    const { isInvalid, message } = validateFile(selectedFile[0]);
    if (isInvalid) {
      setUploadOverlayOpen(false);
      showAlertToast(
        'warning',
        message
      );
      return;
    }
    setUploadFiles(selectedFile);

    showUploadDialog();
  };

  const showUploadDialog = () => {
    setUploadOverlayOpen(false);
    setUploadDialogOpen(true);
  };

  const showAlertToast = (type: string, text: string) => {
    setAlertToast({
      type,
      text,
    });

    setAlertToastOpen(!!text);
  };

  const deselectAll = () => {
    setDocuments((prevState: any) => {
      const documentListCopy = [...prevState];
      documentListCopy.forEach((document: Document) => {
        document.selected = false;
      });

      return documentListCopy;
    });
  };

  const updateField = (
    documentId: string,
    field: 'name' | 'link' | 'category' | 'note',
    value: any
  ) => {
    setDocuments((prevState: any) => {
      const documentsCopy = [...prevState];
      documentsCopy.forEach((document: Document) => {
        if (document.id === documentId) {
          switch (field) {
            case 'name':
              document.fileOriginalName = value;
              break;
            case 'link':
              document.loading = value;
              break;
            case 'category':
              document.documentType = value;
              break;
            case 'note':
              document.notes = value;
              break;
          }
        }
      });
      return documentsCopy;
    });
  };

  useEffect(() => {
    if (documents) {
      setSelectedDocuments(
        documents.filter((document: Document) => !!document.selected)
      );
    }
  }, [documents]);

  return (
    <div
      className={`${styles.documents}`}
      onDrop={dropHandler}
      onDragOver={(event: any) => {
        if (!uploadDialogOpen) {
          event.preventDefault();
          setUploadOverlayOpen(true);
        }
      }}
    >
      <ControlBar
        policyList={[]}
        categoryList={categoryList}
        showAutoPullMessage={null}
        search={search}
        setSearch={setSearch}
        setUploadFiles={setUploadFiles}
        showUploadDialog={showUploadDialog}
        autoPullDisabled={true}
        refreshData={refreshData}
        showAlertToast={showAlertToast}
        forOppDocsView={true}
      />
      <DocumentTable
        documents={filteredDocuments}
        setSelectedDocuments={setSelectedDocuments}
        tableText={tableText}
        showAlertToast={showAlertToast}
        options={{
          columns: {
            link: {
              hide: true,
            },
          },
        }}
      />
      <ActionBar
        selectedDocuments={selectedDocuments}
        showAlertToast={showAlertToast}
        categoryList={categoryList}
        deselectAll={deselectAll}
        refreshData={refreshData}
        updateField={updateField}
        userName={userName}
        userId={accountId}
        customer={customer}
        associatedContactList={contactList}
        opportunityList={opportunityList}
        policyList={policyList}
      />
      <Upload
        open={uploadDialogOpen}
        closeDialog={() => {
          setUploadDialogOpen(false);
        }}
        files={uploadFiles}
        categoryList={categoryList}
        policyList={[]}
        opportunityList={[]}
        accountId={accountId}
        showAlertToast={showAlertToast}
        refreshData={refreshData}
        pca={instance}
        userName={userName}
        linkedOppId={opportunityId}
      />
      <UploadOverlay
        open={uploadOverlayOpen}
        onClose={(event: any) => {
          event.preventDefault();
          setUploadOverlayOpen(false);
        }}
      />
      <AlertToast
        open={alertToastOpen}
        closeAlert={() => {
          setAlertToastOpen(false);
        }}
        type={alertToast.type}
        text={alertToast.text}
      />
    </div>
  );
};

export default DocumentsForOpportunity;
