import { Claim } from '@bwinsurance/meta-rater-types';
import { ReactNode } from 'react';
import { DisplayValueOptional } from '../DisplayValue';
import { getStringAddress } from '@/controllers/common/utils';
import SubheadingDivider from '../SubheadingDivider';

interface ClaimDisplayProps {
  claim: Claim;
}

const ClaimDisplay: React.FC<ClaimDisplayProps> = ({ claim }) => (
  <div className="flex flex-row flex-wrap gap-5">
    <DisplayValueOptional label="Claim type" value={claim.type} />
    <DisplayValueOptional label="Status" value={claim.status} />
    <DisplayValueOptional label="Payout" value={claim.payout} isCurrency />
    <DisplayValueOptional
      label="Date occurred"
      value={claim.dateOccurred}
      isDate
    />
    <DisplayValueOptional label="Date closed" value={claim.dateClosed} isDate />
    <DisplayValueOptional
      label="Carrier claim id"
      value={claim.carrierClaimId}
    />
    <DisplayValueOptional
      label="Address"
      value={getStringAddress(claim.address)}
    />
  </div>
);

interface ClaimListProps {
  list: Claim[];
}

export const ClaimList: React.FC<ClaimListProps> = ({ list }) => {
  // If array is empty, return null
  if (!list.length) {
    return null;
  }
  return (
    <SubheadingDivider headingText="Claims">
      {list.map((claim) => (
        <ClaimDisplay claim={claim} key={claim.id} />
      ))}
    </SubheadingDivider>
  );
};
