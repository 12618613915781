import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { FormField } from '@/services/forms/models/formTypes';
import { cn } from '@/services/lib/utils';
import { Check, ChevronDown } from 'lucide-react';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { FieldError, get, useFormContext, useFormState } from 'react-hook-form';
import { useGetDynamicOptions } from './useGetDynamicOptions';

export type DynamicDropdownProps = {
  field: FormField;
};

const DynamicDropdown: FC<DynamicDropdownProps> = ({ field }) => {
  const { setValue, watch, register } = useFormContext();
  const {
    id,
    name,
    display,
    disabled,
    placeholder,
    syncWith,
    optionsBy,
    highlight,
  } = field;
  const [open, setOpen] = useState(false);

  const selectedValue = watch(name);

  const { dynamicOptions: options } = useGetDynamicOptions(optionsBy || '');

  const { errors } = useFormState();
  const error: FieldError = get(errors, name);

  const handleSelect = (newSelectedValue: string) => {
    setValue(name, newSelectedValue === selectedValue ? undefined : newSelectedValue, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    if (syncWith) {
      setValue(syncWith.fieldName, newSelectedValue, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    setOpen(false);
  };

  register(name, { ...(field.validation ? { ...field.validation } : {}) });

  const displayText = useMemo(() => {
    // handle no value
    if (!selectedValue || !options || options.length === 0) {
      return placeholder;
    }

    // handle selected value not in options
    const matchedDisplay = options.find(
      (option) => String(option.key).trim() === selectedValue
    )?.display;

    if (!matchedDisplay) {
      setValue(id || name, undefined, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      if (syncWith) {
        setValue(syncWith.fieldName, undefined, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
      return placeholder;
    }

    return matchedDisplay;
  }, [selectedValue, options]);

  return (
    <div className="flex flex-col min-w-[236px] max-w-[492px] gap-1.5">
      <Label htmlFor={id || name}>
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{display}</span>
      </Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            role="combobox"
            aria-expanded={open}
            className="justify-between"
            id={id || name}
            disabled={!options.length || disabled}
            data-testid={id || name}
          >
            <span
              className={`${
                displayText === placeholder ? 'text-[#d3d3d3]' : ''
              }`}
            >
              {displayText}
            </span>
            <ChevronDown className="h-3.5 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="min-w-[236px] p-0">
          <Command>
            <CommandInput placeholder="Search option..." />
            <CommandList>
              <CommandEmpty>No options found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    key={option.key}
                    value={option.key}
                    onSelect={handleSelect}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedValue === option.key
                          ? 'opacity-100'
                          : 'opacity-0'
                      )}
                    />
                    {option.display}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {error && (
        <p className="text-sm text-light-text-error">{error.message}</p>
      )}
    </div>
  );
};

export { DynamicDropdown };
