/* eslint-disable @typescript-eslint/no-explicit-any */
import { Loader } from '@googlemaps/js-api-loader';
import { useCallback, useEffect, useRef, useState, type FC } from 'react';
import {
  FieldError,
  FieldValues,
  get,
  useFormContext,
  UseFormRegister,
  useFormState,
} from 'react-hook-form';

import { mapsApiKey } from '@/services/common/config';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FormField } from '@/services/forms/models/formTypes';

export type AddressAutoCompleteFieldProps = {
  field: FormField;
  register: UseFormRegister<FieldValues>;
};

/**
 * AddressAutoCompleteField - Field to use type-ahead to suggest addresses verified by Google
 * while a user is typing in the address. This will populate all common address property fields on the root object provided.
 * Field name should always be `<entity>.lineOne`, for example: currentAddress.lineOne
 */
const AddressAutoCompleteField: FC<AddressAutoCompleteFieldProps> = ({ field, register }) => {
  const { id, name, display, placeholder, disabled, highlight } = field;
  const { errors } = useFormState();
  const { setValue } = useFormContext();
  const error: FieldError = get(errors, name);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [loaded] = useMapsLibrary();

  const initMap = useCallback(
    (input: HTMLInputElement) => {
      if (!loaded) {
        // maps places library hasn't loaded yet
        console.warn('maps places library is not loaded');
        return;
      }
      console.debug('maps places library loaded');

      // Transfers data from the given address object to the form by matching on the question's name, if available.
      const transferValue = (address: Record<string, any>, fieldName: string) => {
        const rootObject = name.replace('.lineOne', '').replace('.line1', '');
        const fullFieldName = rootObject ? `${rootObject}.${fieldName}` : fieldName;
        // console.log('transferValue', { rootObject, fieldName, address }); // to help debug
        setValue(fullFieldName, address[fieldName], {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      };

      const autocomplete = new google.maps.places.Autocomplete(input);
      // just U.S.
      autocomplete.setComponentRestrictions({
        country: ['us'],
      });

      // we only need the address_component(s)
      autocomplete.setFields(['address_component', 'formatted_address']);

      // listen to place_changed events
      autocomplete.addListener('place_changed', function () {
        const place = autocomplete.getPlace();
        if (!place.address_components) {
          // show a simple error, in case the user entered an invalid address
          window.alert('The following address has not been found: "' + place.name + '"');
          return;
        }

        // get the address components
        const streetName = getAddressComponent(place, 'route');
        const streetNumber = getAddressComponent(place, 'street_number');
        const postalCode = getAddressComponent(place, 'postal_code');
        const neighborhood = getAddressComponent(place, 'neighborhood');
        let sublocality = getAddressComponent(place, 'sublocality');
        if (!sublocality) {
          sublocality = getAddressComponentLevel(place, 'sublocality');
        }
        const subpremise = getAddressComponent(place, 'subpremise');
        let city = getAddressComponent(place, 'locality');
        if (!city) {
          city = getAddressComponent(place, 'postal_town');
        }
        if (!city) {
          city = sublocality;
        }
        if (!city) {
          city = neighborhood;
        }
        const administrativeArea = getAddressComponentLevel(place, 'administrative_area', true);
        const administrativeAreaLong = getAddressComponentLevel(
          place,
          'administrative_area',
          false
        );
        const countryShort = getAddressComponent(place, 'country', true);
        const country = getAddressComponent(place, 'country');
        const formattedAddress = place.formatted_address;
        const address = {
          streetName: streetName,
          StreetName: streetName,
          streetNumber: streetNumber,
          StreetNumber: streetNumber,
          line1: `${streetNumber || ''} ${streetName}`,
          Line1: `${streetNumber || ''} ${streetName}`,
          lineOne: `${streetNumber || ''} ${streetName}`,
          line2: subpremise,
          Line2: subpremise,
          lineTwo: subpremise,
          postalCode: postalCode,
          PostalCode: postalCode,
          neighborhood: neighborhood,
          Neighborhood: neighborhood,
          sublocality: sublocality,
          Sublocality: sublocality,
          city: city,
          City: city,
          state: administrativeAreaLong,
          State: administrativeAreaLong,
          stateCode: administrativeArea,
          administrativeArea: administrativeArea,
          AdministrativeArea: administrativeArea,
          countryShort: countryShort,
          CountryShort: countryShort,
          country: country,
          Country: country,
          formattedAddress: formattedAddress,
          FormattedAddress: formattedAddress,
        };
        // debug help
        // console.info('Retrieved address data:', place.address_components);
        // console.info('Formatted address: %s', place.formatted_address);
        // console.info('form address data:', address);

        // transfer all values to questions, if they exist
        Object.getOwnPropertyNames(address).forEach(function (fieldName) {
          transferValue(address, fieldName);
        });
      });

      // set the lookup type to address
      autocomplete.setTypes(['address']);
      // set strict bounds
      autocomplete.setOptions({ strictBounds: true });
    },
    [name, setValue, loaded]
  );

  useEffect(() => {
    if (inputRef) {
      initMap(inputRef.current as HTMLInputElement);
    }
  }, [inputRef, initMap]);

  const { ref, ...inputProps } = register(name, {
    setValueAs: (value) => value || undefined,
    ...(field.validation ? { ...field.validation } : {}),
  });

  return (
    <div className="flex flex-col min-w-[236px] max-w-[492px] gap-1.5">
      <Label htmlFor={id || name}>
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{display}</span>
      </Label>
      <Input
        {...inputProps}
        type={'text'}
        ref={(e) => {
          ref(e);
          inputRef.current = e; // you can still assign to ref
        }}
        id={id || name}
        placeholder={placeholder}
        data-testid={id || name}
        disabled={disabled}
        autoComplete="none"
      />
      {error && <p className="text-sm text-light-text-error">{error.message}</p>}
    </div>
  );
};

export { AddressAutoCompleteField };

function getAddressComponent(place: any, type: string, useShortName = false): string | undefined {
  for (const component of place.address_components) {
    if (component.types[0] === type) {
      if (useShortName) {
        return component.short_name;
      }
      return component.long_name;
    }
  }
}

// Retrieves the long name of the address component from the designated place with the designated hierarchical type with the lowest level.
function getAddressComponentLevel(
  place: any,
  type: string,
  useShortName = false,
  startLevel = 1
): string | undefined {
  for (let i = startLevel; i < 6; i++) {
    const compName = type + '_level_' + i;
    const result = getAddressComponent(place, compName, useShortName);
    if (result) {
      return result;
    }
  }
}

const useMapsLibrary = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      const loader = new Loader({
        apiKey: mapsApiKey,
        version: 'weekly',
        libraries: ['places'],
        // ...additionalOptions,
      });

      const loadLibrary = async () => {
        if (loader) {
          const result = await loader.importLibrary('maps');
          setLoaded(!!result);
        }
      };
      loadLibrary();
    }
  }, [loaded, setLoaded]);
  return [loaded];
};
