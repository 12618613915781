import axios from 'axios';

const LOGGER_LEVELS = ['error', 'warn', 'info', 'log'];
type LoggerLevel = (typeof LOGGER_LEVELS)[number];

type Logger = Record<LoggerLevel, (message: string, cause?: unknown) => void>;

const clientLogger: Logger = (() => {
  const logToAPICurry = async (
    level: LoggerLevel,
    message: string,
    cause?: unknown
  ) => {
    try {
      await axios.post('/logger', { level, message, cause });
    } catch (error) {
      // This is the only place we should be calling "console"-anything on the client since we've tried every other way.
      // eslint-disable-next-line no-console
      console.error('Log POST failed', error);
    }
  };

  const innerLogger: Partial<Logger> = {};
  for (const _level of LOGGER_LEVELS) {
    const level: LoggerLevel = _level as LoggerLevel;
    innerLogger[level] = (message: string, cause?: unknown) =>
      void logToAPICurry(level, message, cause);
  }

  return innerLogger as unknown as Logger;
})();

export default clientLogger;
