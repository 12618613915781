import AlertToast, {
  AlertToast as AlertToastInterface,
} from '../../../../components/shared/alertToast';
import { FusionForm } from '../../../../models/forms/form';
import FormList from '../formList/FormList';
import Button from '../../../../components/shared/button';
import Email from './../dialogs/EmailController';
import { PolicyKeySet, Question } from './types';
import { useFormContext } from '../form/FormContext';
import styles from './FormView.module.css';
import { useMemo, useState } from 'react';
import { createForm } from '@/services/forms/formsService';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { APIForm, FormRow } from '@bwinsurance/shared-components';

interface Props {
  alertToastOpen: boolean;
  dialogOpen: boolean;
  setDialogOpen(isOpen: boolean): void;
  alertToast: AlertToastInterface | undefined;
  closeAlertToast(): void;
  showAlertToast(type: string, text: string): void;
  username: string | undefined;
  accoundId: string;
}

const FormView: React.FC<Props> = ({
  alertToastOpen,
  setDialogOpen,
  showAlertToast,
  dialogOpen,
  alertToast,
  closeAlertToast,
  username,
  accoundId,
}) => {
  const {
    selectedPolicyKeys,
    selectedFormListKeys,
    forms,
    associatedContactList,
    fetchedData,
    uwMemo,
  } = useFormContext();
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  const hasSelectedItems =
    selectedFormListKeys.length !== 0 || selectedPolicyKeys.length !== 0;
  const hasMultipleSelectedItems =
    selectedFormListKeys.length + selectedPolicyKeys.length > 1;

  const selectedForms: FusionForm[] = forms.filter((form) =>
    selectedFormListKeys.includes(form.key)
  );

  const selectedPolicyKeyListWithFormNames: PolicyKeySet[] = useMemo(() => {
    return [...selectedPolicyKeys].map((policyKeyItem) => {
      const policyKeyItemCopy = { ...policyKeyItem };
      const correspondingForm = forms.find(
        (form) => form.key === policyKeyItemCopy.formKey
      );
      if (correspondingForm) {
        let formName = correspondingForm.name;
        if (correspondingForm.policies && correspondingForm.policies.length) {
          const correspondingPolicy = correspondingForm?.policies.find(
            (policy) => policy.amsPolicyId === policyKeyItemCopy.policyKey
          );
          if (correspondingPolicy) {
            formName = `${formName} |  ${correspondingPolicy?.carrier.name} | ${correspondingPolicy?.policyNumber}`;
          }
        }
        policyKeyItemCopy.formName = formName;
      }
      return policyKeyItemCopy;
    });
  }, [selectedPolicyKeys]);

  const sendForms = async () => {
    if (Object.keys(uwMemo).length) {
      //check that all fields are populated
      for (const [policyId, questionList] of Object.entries(uwMemo)) {
        const missingField = questionList.some(
          (question: Question) => !question.text || !question.type
        );
        if (missingField) {
          showAlertToast('warning', 'Missing underwriting memo field');
          return;
        }
      }

      setLoading(true);
      for (const [policyId, questionList] of Object.entries(uwMemo)) {
        const newForm: Pick<APIForm, 'name' | 'dbAction' | 'content'> = {
          name: 'Underwriting Memo',
          dbAction: 'Web Form Cust UW Memo Response',
          content: {
            rows: questionList
              .sort((a: Question, b: Question) => a.order - b.order)
              .map((question: Question, index: number): FormRow => {
                return {
                  rowType: question.type,
                  label: question.text,
                  disabled: false,
                  required: true,
                  name: question.text,
                };
              }),
          },
        };
        try {
          const newFormResponse = await createForm(newForm, authedFetch);
          //find the form in the list and and swap the newly created form key in for 'underwriting-memo'
          selectedPolicyKeyListWithFormNames.forEach(
            (policyKeySet: PolicyKeySet) => {
              if (policyKeySet.policyKey === policyId) {
                policyKeySet.formKey = newFormResponse.key;
              }
            }
          );

          setDialogOpen(true);
        } catch (err) {
          showAlertToast('warning', 'Error creating underwriting memo');
        }
        setLoading(false);
      }
    } else {
      setDialogOpen(true);
    }
  };

  return (
    <div className={styles['form-view-container']}>
      {/* <ControlBar formCount={formCount} /> */}
      {fetchedData.state !== 'complete' && <h4>Loading...</h4>}
      <FormList accountId={accoundId} />
      <div>
        <Button
          type={'primary'}
          callback={sendForms}
          disabled={!hasSelectedItems}
          theme={'crm'}
          loading={loading}
        >
          Send
          {hasSelectedItems &&
            ` ${selectedFormListKeys.length + selectedPolicyKeys.length} Form${
              hasMultipleSelectedItems ? 's' : ''
            }`}
        </Button>
      </div>
      {alertToast && (
        <AlertToast
          open={alertToastOpen}
          closeAlert={closeAlertToast}
          type={alertToast.type}
          text={alertToast.text}
        />
      )}
      <Email
        selectedFormList={selectedForms}
        selectedPolicyKeys={selectedPolicyKeyListWithFormNames}
        associatedContactList={associatedContactList}
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        showAlertToast={showAlertToast}
        username={username}
      />
    </div>
  );
};

export default FormView;
