export const stateCodes = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const stateCodeOptions = [
  { key: 'AK', display: 'AK' },
  { key: 'AL', display: 'AL' },
  { key: 'AR', display: 'AR' },
  { key: 'AZ', display: 'AZ' },
  { key: 'CA', display: 'CA' },
  { key: 'CO', display: 'CO' },
  { key: 'CT', display: 'CT' },
  { key: 'DC', display: 'DC' },
  { key: 'DE', display: 'DE' },
  { key: 'FL', display: 'FL' },
  { key: 'GA', display: 'GA' },
  { key: 'HI', display: 'HI' },
  { key: 'IA', display: 'IA' },
  { key: 'ID', display: 'ID' },
  { key: 'IL', display: 'IL' },
  { key: 'IN', display: 'IN' },
  { key: 'KS', display: 'KS' },
  { key: 'KY', display: 'KY' },
  { key: 'LA', display: 'LA' },
  { key: 'MA', display: 'MA' },
  { key: 'MD', display: 'MD' },
  { key: 'ME', display: 'ME' },
  { key: 'MI', display: 'MI' },
  { key: 'MN', display: 'MN' },
  { key: 'MO', display: 'MO' },
  { key: 'MS', display: 'MS' },
  { key: 'MT', display: 'MT' },
  { key: 'NC', display: 'NC' },
  { key: 'ND', display: 'ND' },
  { key: 'NE', display: 'NE' },
  { key: 'NH', display: 'NH' },
  { key: 'NJ', display: 'NJ' },
  { key: 'NM', display: 'NM' },
  { key: 'NV', display: 'NV' },
  { key: 'NY', display: 'NY' },
  { key: 'OH', display: 'OH' },
  { key: 'OK', display: 'OK' },
  { key: 'OR', display: 'OR' },
  { key: 'PA', display: 'PA' },
  { key: 'RI', display: 'RI' },
  { key: 'SC', display: 'SC' },
  { key: 'SD', display: 'SD' },
  { key: 'TN', display: 'TN' },
  { key: 'TX', display: 'TX' },
  { key: 'UT', display: 'UT' },
  { key: 'VA', display: 'VA' },
  { key: 'VT', display: 'VT' },
  { key: 'WA', display: 'WA' },
  { key: 'WI', display: 'WI' },
  { key: 'WV', display: 'WV' },
  { key: 'WY', display: 'WY' }
];
