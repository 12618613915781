import { Document } from '@/models/document/document';
import Tooltip from '../../shared/tooltip';
import styles from './documentTable.module.css';
import '@/theme.module.css';
import {
  botUser,
  formatTerm,
  getLinksHover,
} from '@/controllers/document/DocumentTableController';
import Icon from '@/components/Icon';

export const linkCellContent = (row: Document, item: string) => {
  const links = row?.links;
  const linkCount =
    (links?.opportunity?.length ?? 0) + (links?.policy?.length ?? 0);

  if (row.loading) {
    return <span className={styles.loadingText}>Loading...</span>;
  } else if (links && linkCount > 1) {
    return (
      <Tooltip message={getLinksHover(links)} position={'auto'} theme={'crm'}>
        <span className={styles.multiplePolicies}>Multiple ({linkCount})</span>
      </Tooltip>
    );
  } else if (row.links?.opportunity?.[0]) {
    const opportunity = row.links.opportunity[0];

    return (
      <div className="flex flex-col gap-0">
        <div>Opportunity</div>
        <small>
          <em>{opportunity?.name}</em>
        </small>
      </div>
    );
  } else if (row?.links?.policy?.[0]) {
    const policy = row.links.policy[0];

    return (
      <div className="flex flex-col gap-0">
        <div>Policy #: {policy.policyNumber}</div>
        <small>
          <em>
            Term: {formatTerm(policy.effectiveDate, policy.expirationDate)}
            {policy.policyStatus ? ` | Status: ${policy.policyStatus}` : ''}
          </em>
        </small>
      </div>
    );
  } else {
    return '-';
  }
};

export const addedByCellContent = (row: Document, item: string) =>
  row.createdBy?.name === botUser ? (
    <div title={botUser}>
      <Icon type={'sparkle'} color={'black'} viewbox={'0 -4 24 24'} />
    </div>
  ) : (
    row.createdBy?.name
  );
