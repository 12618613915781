import { useEffect, useRef } from 'react';
import styles from './textArea.module.css';
import '../../theme.module.css';

/**
 * Displays a styled text area field.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {string} config.label - The text to display above the input.
 * @param {string} config.value - The value of the input field.
 * @param {function} config.onChange - The function to handle changes in the input field.
 * @param {boolean} [config.readonly] - Flag indicating whether the field is editable.
 * @param {boolean} [config.required] - Flag indicating whether the field is required.
 * @param {boolean} [config.disabled] - Flag indicating whether the field is disabled.
 * @param {string} [config.placeholder] - Placeholder text.
 * @param {string} [config.classes] - Any additional tailwind css classes to apply to the input.
 * @param {string} [config.theme] - Indicates which style theme to apply: bw or crm.
 *
 * @example
 * <TextArea label={'Email address'}  value={emailAddress} onChange={(event) => setEmailAddress(event)} />
 */
export default function TextArea({
  label,
  value,
  onChange,
  readonly = false,
  required = false,
  disabled = false,
  placeholder,
  classes,
  theme = 'bw',
}: {
  label?: string;
  value: string;
  onChange: any;
  readonly?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  classes?: string;
  theme?: 'bw' | 'crm';
}) {
  const inputRef: { current: any } = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      setTimeout(() => inputRef.current.select());
    }
  }, [inputRef]);

  return (
    <label className={[styles[theme], classes].join(' ')}>
      {label && <span className={styles.label}>{label}</span>}
      {required && <span className={styles.required}>*</span>}
      <textarea
        className={styles.input}
        onChange={(event) => onChange(event.target.value)}
        value={value}
        ref={inputRef}
        readOnly={readonly}
        placeholder={placeholder}
        disabled={disabled}
        rows={3}
      />
    </label>
  );
}
