import { useEffect, useRef, useState } from 'react';
import styles from './tooltip.module.css';
import '../../theme.module.css';

type TooltipPositions = 'above' | 'below' | 'auto' | 'right';
const positionMapping = {
  above: { bottom: '100%' },
  below: { top: '100%' },
  right: { top: '15%', left: '80%' },
  auto: { top: '', bottom: '', left: '', right: '' },
};

/**
 * Displays a styled tooltip.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {string} config.message - The text to display in the tooltip.
 * @param {HTML} config.children - The element to associate the tooltip to.
 * @param {boolean} [config.disabled] - Flag indicating whether the tooltip is disabled.
 * @param {string} [config.position] - Whether the tooltip should be displayed above or below the associated element.
 * @param {boolean} [config.hidden] - Flag indicating whether the tooltip is hidden.
 * @param {string} [config.theme] - Indicates which style theme to apply: bw or crm.
 *
 * @example
 * <Tooltip
        message={`Tooltip for the button.`}
    >
        <button>Click here!</button>
    </Tooltip>
 */

export default function Tooltip({
  message,
  children,
  disabled = false,
  position = 'above',
  id,
  hidden = false,
  theme = 'bw',
}: {
  message: string;
  children: any;
  disabled?: boolean;
  position?: TooltipPositions;
  id?: string;
  hidden?: boolean;
  theme?: 'bw' | 'crm';
}) {
  const tooltipRef: { current: any } = useRef(null);
  const [positionFlag, setPositionFlag] = useState<TooltipPositions>(position);

  // this sets the position of the tooltip
  // to make sure it's onscreen
  useEffect(() => {
    function updateTooltipPosition() {
      if (tooltipRef.current && position === 'auto') {
        const rect = tooltipRef.current.getBoundingClientRect();
        positionMapping.auto = {
          bottom: '',
          top: '',
          left: '',
          right: '',
        };

        if (rect.top >= window.innerHeight / 2) {
          positionMapping.auto.bottom = '100%';
        } else {
          positionMapping.auto.top = '100%';
        }

        if (rect.left >= window.innerWidth / 2) {
          positionMapping.auto.right = '0';
        } else {
          positionMapping.auto.left = '100%';
        }
      }
    }

    window.addEventListener('resize', updateTooltipPosition);
    updateTooltipPosition();
    return () => window.removeEventListener('resize', updateTooltipPosition);
  }, [position]);

  if (hidden) {
    return children;
  }

  return (
    <div className={`${styles[theme]} ${styles.container}`} ref={tooltipRef}>
      {children}
      {!disabled && (
        <div
          id={id}
          style={positionMapping[positionFlag]}
          className={styles.tooltip}
        >
          {message}
        </div>
      )}
    </div>
  );
}
