import { useMemo, useState } from 'react';
import AlertToast, {
  AlertToast as AlertToastInterface,
} from '@/components/shared/alertToast';

export interface AlertToastWithId extends AlertToastInterface {
  id: number;
}

const spacing = 10;

const useAlertToast = () => {
  const [toastList, setToastList] = useState<AlertToastWithId[]>([]);
  const [toastHeights, setToastHeights] = useState<number[]>([]);

  const addToast = (toasts: AlertToastInterface[]) => {
    const newToasts = toasts.map((toast, index) => {
      return {
        ...toast,
        id: Date.now() + index,
      };
    });
    setToastList((prevToasts) => [...prevToasts, ...newToasts]);
  };

  const removeToast = (toastId: number) => {
    setToastList((prevToasts) =>
      prevToasts.filter((toast) => toast.id !== toastId)
    );
  };

  const removeAllToast = () => {
    setToastList([]);
  };

  const alertToasts = useMemo(() => {
    return (
      <>
        {toastList.map((toast, index) => {
          let calcBottom = 20;
          // Calculate bottom position based on previous toasts' heights
          for (let i = 0; i < index; i++) {
            calcBottom += (toastHeights[i] || 0) + spacing;
          }

          return (
            <AlertToast
              open={!!toastList.length}
              closeAlert={() => removeToast(toast.id)}
              type={toast.type}
              text={toast.text}
              action={toast.action}
              additionalStyles={{ bottom: calcBottom }}
              index={index}
              setToastHeights={setToastHeights}
              key={toast.id + index}
            />
          );
        })}
      </>
    );
  }, [toastHeights, toastList]);

  return { AlertToastList: alertToasts, addToast, removeAllToast };
};

export { useAlertToast };
