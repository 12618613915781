import Checkbox from '@/components/shared/checkbox';
import { Policy } from '../../../../models/forms/policy';
import { useFormContext } from '../form/FormContext';
import styles from './FormListSection.module.css';
import { getPolicyFormLink, formatTerm } from './helpers';
import { featureFlagCompleteForm } from '@/services/common/config';
import FormBuilder from '../formBuilder/FormBuilder';
import { useMsal } from '@azure/msal-react';
import { formsConfig } from '@/services/common/config';
import { useMemo } from 'react';
import { FusionForm } from '../../../../models/forms/form';
import { PolicyKeySet } from '../form/types';

interface Props {
  form: FusionForm;
  accountId: string;
}

const FormListSection: React.FC<Props> = ({ form, accountId }) => {
  const { togglePolicy, selectedPolicyKeys } = useFormContext();
  const { instance } = useMsal();
  const underwritingMemoFormKey = 'underwriting-memo';

  const uwMemoEnabled = useMemo(() => {
    if (instance) {
      const username = instance.getActiveAccount()?.username;
      if (!username) {
        return false;
      } else {
        return formsConfig.underwritingAllowedEmails
          .split(';')
          .map((allowedEmail: unknown) =>
            typeof allowedEmail === 'string'
              ? allowedEmail.toLocaleLowerCase()
              : allowedEmail
          )
          .includes(username.toLocaleLowerCase());
      }
    } else {
      return false;
    }
  }, [instance]);

  if (form.key === underwritingMemoFormKey && !uwMemoEnabled) {
    return;
  }

  return (
    <div className={styles.form}>
      <header>{form.name}</header>
      <div className={styles['policy-list']}>
        {form.policies?.map((policy) => {
          const isSelected = selectedPolicyKeys.some(
            ({ formKey: innerFormKey, policyKey }) =>
              innerFormKey === form.key && policyKey === policy.amsPolicyId
          );
          return (
            <>
              <div className={styles['policy']}>
                <div className={styles['checkbox-row']}>
                  <Checkbox
                    checked={isSelected}
                    onChange={() =>
                      togglePolicy(
                        policy.amsPolicyId,
                        form.key,
                        form.metadata.origin
                      )
                    }
                    label={`${policy.carrier.name} (${policy.policyNumber})`}
                    theme={'crm'}
                    disabled={selectedPolicyKeys.some(
                      (policyKey: PolicyKeySet) => {
                        const policyKeyOrigin = policyKey.origin || 'user';
                        const formOrigin = form.metadata.origin || 'user';
                        return policyKeyOrigin !== formOrigin;
                      }
                    )}
                  />
                  {featureFlagCompleteForm && form.metadata.submitInFusion && (
                    <a
                      className={styles['form-link']}
                      href={getPolicyFormLink(policy, accountId, form.key)}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Complete Form
                    </a>
                  )}
                </div>
                <em className={styles['policy-details']}>{`Term: ${formatTerm(
                  policy.effectiveDate,
                  policy.expirationDate
                )}  |  Status: ${policy.policyStatus}`}</em>
              </div>
              {form.key === underwritingMemoFormKey && (
                <FormBuilder policy={policy} isSelected={isSelected} />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default FormListSection;
