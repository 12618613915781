import type { FC } from 'react';
import type { FormPanel } from '@/services/forms/models/formTypes';
import Section from '../../Section';
import { FormSection } from '../FormSection';

type Field = {
  display: string;
  page: string;
};

type FormPanelProps = {
  panel: FormPanel;
  searchResults?: Field[] | null;
};

const FormPanel: FC<FormPanelProps> = ({ panel, searchResults }) => {
  return (
    <div className="flex flex-col gap-8 -ml-8">
      <Section
        key={panel.id}
        headingText={panel.display}
        materialIcon={panel.icon}
      >
        {panel.elements.map((section) => {
          if (section.type === 'section') {
            return (
              <FormSection section={section} searchResults={searchResults} />
            );
          }
          return null;
        })}
      </Section>
    </div>
  );
};

export { FormPanel };
