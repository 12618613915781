import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './table.module.css';
import '../../theme.module.css';
import Icon from '../Icon';

/**
 * Displays a table.
 * 
 * @param {Object} config - The configuration object for this component.
 * @param {Column[]} config.columns - The column configuration object.
 * @param {Object[]} config.data - The list of object to populate the rows.
 * @param {Object} config.noResults - The 'no results' configuration object.
 * @param {string} config.noResults.text - The main no results text.
 * @param {string} config.noResults.subText - The secondary no results text.
 * @param {string} [config.classes] - Any additional tailwind css classes to apply to the table. 
 * 
 * @example
 * <Table 
        classes='w-full' 
        columns={columns} 
        data={documentList} 
        noResults={{text: 'Looks empty here...', subText: 'Something missing? Auto-pull it from the carrier.'}}
    />
 */
export function Table({
  columns,
  data,
  tableText,
  classes,
  theme = 'bw',
}: {
  columns: Column[];
  data: any[] | undefined;
  tableText: TableTextInterface;
  classes?: string;
  theme?: 'bw' | 'crm';
}) {
  const emptyList: any[] = [];
  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [order, setOrder] = useState<string>('asc');

  const getDefaultSorting = (defaultColumn: Column[]) => {
    defaultColumn.forEach((column: Column) => {
      if (column.sortByOrder) {
        setSortedColumn(column.accessor);
        setOrder(column.sortByOrder);
      }
    });
  };

  const handleSortingChange = (accessor: string) => {
    const sortOrder =
      accessor === sortedColumn && order === 'asc' ? 'desc' : 'asc';
    setSortedColumn(accessor);
    setOrder(sortOrder);
  };

  const handleSorting = () => {
    if (!data) {
      return emptyList;
    }

    const defaultSort = (a: any, b: any) => {
      const sortField = getSortField();
      if (!a[sortField]) {
        return order === 'asc' ? -1 : 1;
      } else if (!b[sortField]) {
        return order === 'asc' ? 1 : -1;
      } else if (a[sortField] === b[sortField]) {
        return 0;
      } else {
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (order === 'asc' ? 1 : -1)
        );
      }
    };

    const getSortField = () => {
      let sortField = sortedColumn;
      columns.forEach((column: Column) => {
        if (column.accessor === sortedColumn && column.sortFieldAccessor) {
          sortField = column.sortFieldAccessor;
        }
      });
      return sortField;
    };

    const sortField = getSortField();
    if (sortField) {
      let columnSortFunction: any;
      columns.forEach((column: Column) => {
        if (column.accessor === sortedColumn && column.sortFunction) {
          columnSortFunction = column.sortFunction;
        }
      });
      const sorted = [...data].sort((a: any, b: any) => {
        if (columnSortFunction) {
          return columnSortFunction(a, b, sortField, order, defaultSort);
        } else {
          return defaultSort(a, b);
        }
      });
      return sorted;
    }
  };

  const sortedData = useMemo(() => {
    if (!sortedColumn) {
      getDefaultSorting(columns);
      return emptyList;
    } else if (sortedColumn && order && data) {
      return handleSorting();
    }
  }, [sortedColumn, order, data]);

  return (
    <table className={[styles[theme], classes].join(' ')}>
      <TableHead>
        <tr>
          {columns.map((column: any, index: number) => {
            return (
              <TableHeadCell
                key={`header-cell-${index}`}
                classes={column.headerClasses}
                sortable={column.sortable}
                handleSortingChange={
                  column.sortable
                    ? () => handleSortingChange(column.accessor)
                    : null
                }
                sortDirection={order}
                currentlySorted={sortedColumn === column.accessor}
              >
                {column.headerContent ? column.headerContent() : column.label}
              </TableHeadCell>
            );
          })}
        </tr>
      </TableHead>
      <tbody>
        {!sortedData?.length && (
          <TableText
            numCols={columns.length}
            text={tableText.text}
            subText={tableText.subText}
            showSpinner={tableText.showSpinner}
            theme={theme}
          />
        )}
        {!!sortedData?.length &&
          sortedData.map((row: any, rowIndex: number) => {
            return (
              <tr
                key={`table-row-${rowIndex}`}
                className={[
                  styles.row,
                  row.selected === true ? styles.selected : undefined,
                ].join(' ')}
              >
                {columns.map((column: any, cellIndex: number) => {
                  return (
                    <TableCell
                      key={`table-cell-${cellIndex}`}
                      classes={column.cellClasses}
                    >
                      {column.cellContent
                        ? column.cellContent(row, row[column.accessor])
                        : row[column.accessor]}
                    </TableCell>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

/**
 * Displays a table header row.
 * 
 * @param {Object} config - The configuration object for this component.
 * @param {HTML} config.children - The markup to display in the header row.
 * 
 * @example
 * <TableHead>
        <tr>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Policy</TableHeadCell>
        </tr>
    </TableHead> 
 */
export function TableHead({ children }: { children?: any }) {
  return <thead className={styles.tableHeader}>{children}</thead>;
}

/**
 * Displays a table header cell.
 * 
 * @param {Object} config - The configuration object for this component.
 * @param {HTML} config.children - The markup to display in the header cell.
 * @param {string} [config.classes] - Any additional tailwind css classes to apply to the header cell.
 * @param {boolean} config.sortable - Flag indicating whether the column can be sorted.
 * @param {function} config.handleSortingChange - Function called to trigger sort.
 * @param {string} config.sortDirection - Sort direction: 'asc' or 'desc'.
 * 
 * @example
 * <TableHead>
        <tr>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Policy</TableHeadCell>
        </tr>
    </TableHead> 
 */
export function TableHeadCell({
  children,
  classes,
  sortable,
  handleSortingChange,
  sortDirection,
  currentlySorted,
}: {
  children?: any;
  classes?: string;
  sortable: boolean;
  handleSortingChange: any;
  sortDirection: string;
  currentlySorted: boolean;
}) {
  return (
    <th
      className={[
        styles.tableHeaderCell,
        sortable ? styles.sortable : undefined,
        currentlySorted ? styles.sorted : undefined,
        classes,
      ].join(' ')}
      onClick={handleSortingChange}
    >
      <div className={styles.headerContainer}>
        <div className={styles.label}>{children}</div>
        {sortable && (
          <div
            className={
              currentlySorted
                ? sortDirection === 'asc'
                  ? styles.flip
                  : undefined
                : styles.flip
            }
          >
            <Icon type={'keyboard_arrow_down'} color={'text-primary'} />
          </div>
        )}
      </div>
    </th>
  );
}

/**
 * Displays a table cell.
 * 
 * @param {Object} config - The configuration object for this component.
 * @param {HTML} config.children - The markup to display in the header cell.
 * @param {string} [config.classes] - Any additional tailwind css classes to apply to the button.
 * 
 * @example
 * <tr>
        <TableCell>
            { document.name }
        </TableCell>
        <TableCell>
            { document.policy }
        </TableCell>
    </tr>
 */
export function TableCell({
  children,
  classes,
}: {
  children?: any;
  classes?: string;
}) {
  return <td className={[styles.tableCell, classes].join(' ')}>{children}</td>;
}

/**
 * Displays a message in the table body.
 * 
 * @param {Object} config - The configuration object for this component.
 * @param {string} config.text - The message header text.
 * @param {string} config.subText - The message body text.
 * @param {boolean} [config.showSpinner] - Whether a spinner should be displayed.
 * @param {number} config.numCols - How many columns the no results cell should span.
 * 
 * @example
 * <tbody>
        {
            !documents.length && 
            <TableText 
                numCols={7} 
                text={'Looks empty here...'} 
                subText={'Something missing? Auto-pull it from the carrier.'} 
            />
        }
        {
            documents.length &&
            <tr>
                <TableCell>
                    { document.name }
                </TableCell>
                <TableCell>
                    { document.policy }
                </TableCell>
            </tr>
        }
    </tbody>
 */
export function TableText({
  text,
  subText,
  showSpinner = false,
  numCols,
  theme = 'bw',
}: {
  text: string;
  subText: string;
  showSpinner?: boolean;
  numCols: number;
  theme?: 'bw' | 'crm';
}) {
  return (
    <tr>
      <td colSpan={numCols} className={styles.tableTextCell}>
        <div className={styles.textContainer}>
          {showSpinner && (
            <div className={styles.spinner}>
              <Icon
                type={'spinner'}
                size={64}
                color={theme === 'bw' ? 'text-primary' : 'black'}
                viewbox={'-2 -2 20 20'}
              />
            </div>
          )}
          <h1 className={styles.text}>{text}</h1>
        </div>
        <h3 className={styles.subText}>{subText}</h3>
      </td>
    </tr>
  );
}

export interface Column {
  label: string;
  accessor: string;
  headerClasses?: string;
  headerContent?: (row: any, item: string) => void;
  cellClasses?: string;
  cellContent?: (row: any, item: string) => void;
  sortable: boolean;
  sortFieldAccessor?: string;
  sortByOrder?: 'asc' | 'desc';
  sortFunction?: (
    a: any,
    b: any,
    sortField: string,
    order: 'asc' | 'desc',
    defaultSort: (a: any, b: any) => number
  ) => number;
}

export interface TableTextInterface {
  text: string;
  subText: string;
  showSpinner?: boolean;
}
