import { useEffect, useState } from 'react';
import Button from '@/components/shared/button';
import Dialog from '@/components/shared/dialog';
import { sendEmail, verifyEmail } from '@/services/document/documentService';
import { Document } from '@/models/document/document';
import Checkbox from '@/components/shared/checkbox';
import TextField from '@/components/shared/textField';
import Icon from '@/components/Icon';
import { Customer } from '@/models/document/customer';
import { Select } from '@/components/shared/select';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { Contact } from '@/models/document/contact';
import { ListOption } from '@/models/common/options';
import styles from './email.module.css';
import '@/theme.module.css';
import TextArea from '@/components/shared/textArea';
import { useDebounce } from '@/controllers/common/hooks/useDebounce';

export default function Email({
  customer,
  selectedDocumentList,
  associatedContactList,
  open,
  closeDialog,
  showAlertToast,
  deselectAll,
}: {
  customer: Customer;
  selectedDocumentList: Document[];
  associatedContactList: Contact[];
  open: boolean;
  closeDialog: any;
  showAlertToast: any;
  deselectAll: () => void;
}) {
  const CUSTOMER = 'customer';
  const INVALID_EMAIL_ADDRESS_MESSAGE =
    'This is an invalid email address. Please update the email on the authorized contact in Fusion.';
  const [selectedOption, setSelectedOption] = useState<string>(CUSTOMER);
  const [emailOptionList, setEmailOptionList] = useState<ListOption[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string>(
    associatedContactList && associatedContactList.length === 1
      ? associatedContactList[0].emailAddress
      : ''
  );
  const debouncedSelectedEmail = useDebounce(selectedEmail);
  const [altEmailAddress, setAltEmailAddress] = useState<string>('');
  const debouncedAltEmail = useDebounce(altEmailAddress);
  const [personalMessage, setPersonalMessage] = useState<string>('');
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [invalidAltEmail, setInvalidAltEmail] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [altEmailErrorMessage, setAltEmailErrorMessage] = useState<string>('');
  const [emailValidating, setEmailValidating] = useState<boolean>(false);
  const [altEmailValidating, setAltEmailValidating] = useState<boolean>(false);
  const [emailValidated, setEmailValidated] = useState<boolean>(false);
  const [altEmailValidated, setAltEmailValidated] = useState<boolean>(false);

  useEffect(() => {
    resetErrors();
    if (
      (selectedOption === CUSTOMER && debouncedSelectedEmail) ||
      (selectedOption !== CUSTOMER && debouncedAltEmail)
    ) {
      handleVerifyEmail();
    }
  }, [debouncedSelectedEmail, debouncedAltEmail, selectedOption]);

  useEffect(() => {
    resetErrors();
  }, [selectedEmail, altEmailAddress]);

  useEffect(() => {
    const mappedValues = associatedContactList.map(
      (contact: Contact) =>
        ({
          label: contact.emailAddress,
        } as ListOption)
    );
    if (associatedContactList.length === 1) {
      mappedValues[0].selected = true;
      setSelectedEmail(mappedValues[0].label);
    }
    setEmailOptionList(mappedValues);
  }, [associatedContactList]);

  const handleEmailSelect = (clickedEmail: ListOption) => {
    setEmailValidated(false);
    setSelectedEmail(clickedEmail.label);

    const emailOptionListCopy = [...emailOptionList];
    emailOptionListCopy.forEach((email: ListOption) => {
      if (email.label === clickedEmail.label) {
        email.selected = true;
      } else {
        email.selected = false;
      }
    });
    setEmailOptionList(emailOptionListCopy);
  };

  const handleVerifyEmail = () => {
    resetErrors();

    if (selectedOption === CUSTOMER) {
      setEmailValidating(true);
    } else {
      setAltEmailValidating(true);
    }

    verifyEmail(
      selectedOption === CUSTOMER ? selectedEmail : altEmailAddress,
      authedFetch
    )
      .then((response) => {
        setEmailValidating(false);
        setAltEmailValidating(false);
        if (response.result.verdict.toLowerCase() === 'invalid') {
          if (selectedOption === CUSTOMER) {
            setInvalidEmail(true);
            setEmailErrorMessage(INVALID_EMAIL_ADDRESS_MESSAGE);
          } else {
            setInvalidAltEmail(true);
            setAltEmailErrorMessage(INVALID_EMAIL_ADDRESS_MESSAGE);
          }
        } else {
          if (selectedOption === CUSTOMER) {
            setEmailValidated(true);
          } else {
            setAltEmailValidated(true);
          }
        }
      })
      .finally(() => {
        setEmailValidating(false);
        setAltEmailValidating(false);
      });
  };

  const handleAltEmailChange = (value: string) => {
    setAltEmailValidated(false);
    setAltEmailAddress(value);
  };

  const handlePersonalMessageChange = (value: string) => {
    setPersonalMessage(value);
  };

  const handleSendEmail = () => {
    if (
      !selectedDocumentList.length || // no documents selected
      (selectedOption === CUSTOMER && !selectedEmail) || // no customer email
      (selectedOption !== CUSTOMER && !altEmailAddress) || // no alternate email
      (selectedOption === CUSTOMER && !emailValidated) || // customer email hasn't yet been validated
      (selectedOption !== CUSTOMER && !altEmailValidated) // alternate email hasn't yet been validated
    ) {
      return;
    }

    setLoading(true);
    sendEmail(
      selectedOption === CUSTOMER ? customer.firstName : '',
      customer.customerId,
      selectedOption === CUSTOMER ? selectedEmail : altEmailAddress,
      selectedDocumentList.map((document: Document) => document.id),
      personalMessage,
      authedFetch
    )
      .then((result: any) => {
        showAlertToast(
          'success',
          `File${selectedDocumentList.length > 1 ? 's' : ''} sent to "${
            selectedOption === CUSTOMER ? selectedEmail : altEmailAddress
          }"`
        );
        resetThenClose();
        deselectAll();
      })
      .catch((error: any) => {
        showAlertToast(
          'warning',
          `Error sending file${selectedDocumentList.length > 1 ? 's' : ''}`
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetErrors = () => {
    setInvalidEmail(false);
    setInvalidAltEmail(false);
    setEmailErrorMessage('');
    setAltEmailErrorMessage('');
  };

  const resetThenClose = () => {
    setSelectedOption(CUSTOMER);
    setSelectedEmail(
      associatedContactList && associatedContactList.length === 1
        ? associatedContactList[0].emailAddress
        : ''
    );
    setAltEmailAddress('');
    setPersonalMessage('');

    const emailOptionListCopy = [...emailOptionList];
    emailOptionListCopy.forEach(
      (email: ListOption) => (email.selected = false)
    );
    if (emailOptionListCopy.length === 1) {
      emailOptionListCopy[0].selected = true;
    }
    setEmailOptionList(emailOptionListCopy);

    resetErrors();

    closeDialog();
  };

  return (
    <Dialog open={open} theme={'crm'} width={800}>
      <div className={styles.header}>
        <h5>Select Recipient</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <div className={styles.body}>
        <div>
          <Checkbox
            label={'Customer\'s authorized contacts'}
            type={'radio'}
            theme={'crm'}
            checked={selectedOption === CUSTOMER}
            onChange={() => setSelectedOption(CUSTOMER)}
          />
          <div className={styles.inputContainer}>
            <Select
              label={''}
              theme={'crm'}
              options={emailOptionList}
              placeholder={
                associatedContactList && associatedContactList.length
                  ? 'Select or enter an email address...'
                  : 'Enter customer\'s email address...'
              }
              updateValue={handleEmailSelect}
              combo={true}
              disabled={selectedOption !== CUSTOMER}
              error={invalidEmail}
              errorMessage={emailErrorMessage}
              loading={emailValidating}
            />
          </div>
        </div>
        <div>
          <Checkbox
            label={'Someone else'}
            type={'radio'}
            theme={'crm'}
            checked={selectedOption === 'other'}
            onChange={() => setSelectedOption('other')}
          />
          <div className={styles.inputContainer}>
            <TextField
              placeholder={'Enter email address...'}
              value={altEmailAddress}
              theme={'crm'}
              onChange={(value: string) => handleAltEmailChange(value)}
              disabled={selectedOption !== 'other'}
              error={invalidAltEmail}
              errorMessage={altEmailErrorMessage}
              loading={altEmailValidating}
            />
          </div>
        </div>
        <TextArea
          label={'Personalized note (optional)'}
          value={personalMessage}
          theme={'crm'}
          placeholder={'Optional note'}
          onChange={(value: string) => handlePersonalMessageChange(value)}
        />
        <div className={styles.preview}>
          <label className={styles.label}>Preview (read-only)</label>
          <div className={styles.container}>
            <p>
              {selectedOption === CUSTOMER
                ? `${customer.firstName},`
                : 'Hello,'}
            </p>
            <p>
              Thank you for reaching out.&nbsp;
              {selectedDocumentList.length > 1 && (
                <span>Here are the documents you requested.</span>
              )}
              {selectedDocumentList.length === 1 && (
                <span>Here's the document you requested.</span>
              )}
              {personalMessage && (
                <span className={styles.personalNote}>{personalMessage}</span>
              )}
              <br />
              Have a bright day!
            </p>
            <p>Your Brightway Team</p>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={handleSendEmail}
          type={'primary'}
          theme={'crm'}
          disabled={
            (selectedOption === CUSTOMER && !selectedEmail) ||
            (selectedOption !== CUSTOMER && !altEmailAddress)
          }
          loading={loading}
        >
          Send
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
