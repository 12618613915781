import { dataverse } from '@/services/common/config';

export const getDynamicsOpportunityDetails = async ({
  accessToken,
  opportunityId,
  queryParams,
}: {
  accessToken: string;
  opportunityId: string;
  queryParams?: { selectFields: string; expandFields: string };
}) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Accept', 'application/json');

  const options = {
    method: 'GET',
    headers: headers,
  };

  const path = queryParams
    ? `/opportunities(${opportunityId})?$select=${queryParams.selectFields}&$expand=${queryParams.expandFields}`
    : `/opportunities(${opportunityId})`;

  const response = await fetch(`${dataverse.baseApi}${path}`, options);

  if (response.status === 500) {
    console.log('Something went wrong, please try again.');
    return null;
  }

  if (!response.ok) {
    console.log('Failed to load dynamics data');
    return null;
  }

  const data = await response.json();

  return data;
};

export const getRaterIntegration = async (
  crmAgentId: string,
  accessToken: string
) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Accept', 'application/json');
  headers.append('Prefer', 'odata.include-annotations="*"');

  const options = {
    method: 'GET',
    headers: headers,
  };

  const params = {
    selectFields:
      '_bw_agent_value,statuscode,bw_rater,bw_tenantid,bw_userid,_bw_product_value',
    filterFields: `_bw_agent_value eq ${crmAgentId} and statuscode eq 1`,
  };

  const path = `/bw_raterintegrations?$select=${params.selectFields}&$filter=${params.filterFields}`;

  const response = await fetch(`${dataverse.baseApi}${path}`, options);

  if (response.status === 500) {
    console.log('Something went wrong, please try again.');
    return null;
  }

  if (!response.ok) {
    console.log('Failed to fetch rater integration data');
    return null;
  }

  const data = await response.json();

  return data.value;
};

export const getAgent = async (crmAgentId: string, accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Accept', 'application/json');
  headers.append('Prefer', 'odata.include-annotations="*"');

  const options = {
    method: 'GET',
    headers: headers,
  };

  const params = {
    selectFields:
      'fullname,firstname,lastname,internalemailaddress,_businessunitid_value,employeeid,isdisabled',
  };

  const path = `/systemusers(${crmAgentId})?$select=${params.selectFields}`;

  const response = await fetch(`${dataverse.baseApi}${path}`, options);

  if (response.status === 500) {
    console.log('Something went wrong, please try again.');
    return null;
  }

  if (!response.ok) {
    console.log('Failed to fetch agent data');
    return null;
  }

  const data = await response.json();

  return data;
};
