import { FusionForm } from '../../../../models/forms/form';
import { Policy, PolicyStatus } from '../../../../models/forms/policy';
import { FetchedDataState, PolicyKeySet } from './types';

const formIncludesPolicy = (form: FusionForm, policy: Policy): boolean => {
  return (form.metadata?.linesOfBusiness as string[])?.includes(
    policy.lineOfBusiness
  );
};

// TODO: make this work without mutating
export const combineFormsAndPolicies = (
  forms: FusionForm[],
  policies: Policy[]
): FusionForm[] => {
  for (const form of forms) {
    form.policies = [];
    for (const policy of policies) {
      if (formIncludesPolicy(form, policy)) {
        form.policies.push(policy);
      }
    }
  }
  return forms.filter((form) => form.policies && form.policies.length > 0);
};

//For customer level forms, metadata IS NOT null, and lineofBusiness should be an empty array
export const getCustomerLevelForms = (forms: FusionForm[]): FusionForm[] => {
  return forms.filter(
    (form) =>
      form.metadata?.linesOfBusiness &&
      form.metadata.linesOfBusiness.length === 0
  );
};

export const policyIsSelected = (
  policyID: string,
  formKey: string,
  currentSelectedList: PolicyKeySet[]
): boolean => {
  const formIsSelected = currentSelectedList.some((selectedKeySet) => {
    return (
      policyID === selectedKeySet.policyKey &&
      selectedKeySet.formKey === formKey
    );
  });
  return formIsSelected;
};

export const getUsernameFromFetchedData = (
  fetchedData: FetchedDataState
): string | undefined => {
  if (fetchedData.state === 'complete') {
    return fetchedData.content.username;
  } else {
    return undefined;
  }
};

export const filterVisiblePolicies = (policies: Policy[]): Policy[] =>
  policies.filter(showPolicy);

const HIDDEN_POLICY_STATUSES: PolicyStatus[] = [
  'Include',
  'Quote',
  'Not Taken',
];

export const showPolicy = (policy: Policy): boolean => {
  if (policy.policyStatus === 'Active') {
    return true;
  }

  if (HIDDEN_POLICY_STATUSES.includes(policy.policyStatus)) {
    return false;
  }

  const oneYearAgo = new Date();
  oneYearAgo.setDate(oneYearAgo.getDate() - 365);

  const expirationDate = new Date(policy.expirationDate);

  // Check if the input date is no earlier than one year ago or in the future
  return expirationDate >= oneYearAgo;
};
