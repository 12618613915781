import { Route, Routes } from 'react-router-dom';

import Agency from '../pages/agency';
import { Logout } from '../pages/auth';
import Home from '../pages/home';
import ViewOpportunity from '../pages/opportunity';
import Profile from '../pages/profile';
import ResourceTable from '../pages/resources';
import OpportunityIntake from '../pages/opportunity/intake';
import OpportunityDocuments from '../pages/opportunity/opportunityDocs';

import Loading from '../pages/loading';

const EmptyPage = () => <p>Not implemented yet</p>;
const ResourceDetail = EmptyPage;

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" index element={<Home />} />
      <Route path="/resources/:resourceType">
        <Route index element={<ResourceTable />} />
        <Route path=":id" element={<ResourceDetail />} />
      </Route>
      <Route path="/profile" element={<Profile />} />
      <Route path="/agency" element={<Agency />} />
      <Route path="/settings" element={<EmptyPage />} />
      <Route path="/help" element={<EmptyPage />} />
      {/* /opportunity/:id - old readonly opportunity view embedded in Dynamics, no longer used */}
      <Route path="/opportunity/:id" element={<ViewOpportunity />} />
      <Route path="/opportunity/:id/intake" element={<OpportunityIntake />} />
      <Route
        path="/opportunity/:id/documents"
        element={<OpportunityDocuments />}
      />
      <Route path="/auth/logout" element={<Logout />} />
      <Route path="/loading" element={<Loading />} />
      <Route path="*" element={<>Not Found</>} />
    </Routes>
  );
};

export default AppRoutes;
