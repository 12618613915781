import { useEffect, useRef } from 'react';
import { IMask } from 'react-imask';
import type { UseFormRegister, FieldValues } from 'react-hook-form';
import type { ConditionalConfig, ValidationConfig } from '@/services/forms/models/formTypes';

type InputMaskArgs = {
  mask: Record<string, any>;
  register: UseFormRegister<FieldValues>;
  value: string;
  setValue: (
    name: string,
    value: any,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
          shouldTouch: boolean;
        }>
      | undefined
  ) => void;
  name: string;
  validation?: ValidationConfig;
  syncWith?: ConditionalConfig;
};

const useInputMask = ({
  mask,
  register,
  setValue,
  value,
  name,
  validation,
  syncWith,
}: InputMaskArgs) => {
  const inputRef = useRef(null);
  const maskRef = useRef<any>(null);

  useEffect(() => {
    if (!inputRef.current) return;

    maskRef.current = IMask(inputRef.current, mask);

    register(name, { ...(validation ? { ...validation } : {}) });

    maskRef.current.on('accept', () => {
      const val = maskRef.current.unmaskedValue
        ? maskRef.current.value
        : maskRef.current.unmaskedValue;
      setValue(name, val, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      if (syncWith) {
        setValue(syncWith.fieldName, val, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    });

    return () => {
      maskRef.current.destroy();
    };
  }, [register, name, setValue, mask, validation]);

  useEffect(() => {
    if (maskRef.current) {
      maskRef.current.value = value || '';
    }
  }, [value]);

  return { inputRef };
};

export { useInputMask };
