import styles from './button.module.css';
import '../../theme.module.css';
import Icon, { IconColor, IconType } from '../Icon';

interface ButtonProps {
  children: any;
  callback?: any;
  type:
    | 'primary-xl'
    | 'primary'
    | 'square-primary'
    | 'secondary-xl'
    | 'secondary'
    | 'square-secondary'
    | 'text'
    | 'link';
  disabled?: boolean;
  icon?: string;
  iconSize?: number;
  loading?: boolean;
  classes?: string;
  theme?: 'bw' | 'crm';
}

const iconColorMap = {
  bw: {
    primary: 'text-primary',
    'primary-xl': 'text-primary',
    'square-primary': 'white',
    secondary: 'text-primary',
    'secondary-xl': 'text-primary',
    'square-secondary': 'text-primary',
    text: 'text-primary',
    link: '',
  },
  crm: {
    primary: 'white',
    'primary-xl': '',
    'square-primary': '',
    secondary: 'black',
    'secondary-xl': '',
    'square-secondary': '',
    text: 'black',
    link: '',
  },
};

/**
 * Displays a customizable styled button.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {HTML} config.children - The text to display in the button.
 * @param {function} [config.callback] - The function to call when the button is clicked.
 * @param {string} config.type - Which type of button to display. Options are 'primary-xl', 'primary', 'square-primary', 'secondary-xl', 'secondary', 'square-secondary', 'text', 'link'
 * @param {boolean} [config.disabled] - Flag indicating if the button is disabled. Default is false.
 * @param {string} [config.icon] - Key for which icon to display in the button.
 * @param {number} [config.iconSize] - The size of the icon.
 * @param {boolean} [config.loading] - Flag indicating if a loading spinner should be displayed.
 * @param {string} [config.classes] - Any additional tailwind css classes to apply to the button.
 * @param {string} [config.theme] - Indicates which style theme to apply: bw or crm.
 *
 * @example
 * <Button callback={showUploadDialog} type={'secondary'} icon={'upload_file'}>Upload</Button>
 */
export default function Button({
  children,
  callback,
  type = 'text',
  disabled = false,
  icon,
  iconSize = 16,
  loading = false,
  classes = '',
  theme = 'bw',
}: ButtonProps) {
  return (
    <button
      className={`${styles[theme]} ${classes} ${styles[type]}`}
      onClick={callback ? callback : undefined}
      disabled={disabled || loading}
    >
      {icon && !loading && theme === 'bw' && (
        <span className={`material-symbols-outlined ${styles.icon}`}>
          {icon}
        </span>
      )}
      {icon && !loading && theme === 'crm' && (
        <Icon
          type={icon as IconType}
          color={iconColorMap.crm[type] as IconColor}
          size={iconSize}
        />
      )}
      {loading && (
        <div className={styles.spinner}>
          <Icon
            type={'spinner'}
            size={18}
            color={iconColorMap[theme][type] as IconColor}
            viewbox={'-4 -4 24 24'}
          />
        </div>
      )}
      {children}
    </button>
  );
}
