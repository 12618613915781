import { IntakeNavOptions } from '@/services/common/constants';
import useNavbarHeight from '@/controllers/common/hooks/useNavbarHeight';
import Tooltip from '../shared/tooltip';
import ErrorBoundary from '../ErrorBoundary';
export type NavItem<T> = {
  icon: JSX.Element;
  value: T;
  tooltipText: string;
};
export type NavConfig<T> = {
  selected: IntakeNavOptions;
  setSelected: React.Dispatch<React.SetStateAction<T>>;
  navItems: NavItem<T>[];
};
const LeftNav = <T extends string>({
  selected,
  setSelected,
  navItems,
}: NavConfig<T>): JSX.Element => {
  // allows us to remove top padding when in embed mode and no navbar present
  const navbarHeight = useNavbarHeight();
  return (
    <div
      className="fixed top-0 bottom-0 h-screen w-16 z-10 bg-light-surfaces-subtle dark:bg-dark-surfaces-subtle flex flex-col items-center border-r border-r-light-borders-tertiary dark:border-r-dark-borders-tertiary"
      style={{ paddingTop: navbarHeight }}
    >
      {navItems.map((item, index) => (
        <Tooltip
          message={item.tooltipText}
          position="right"
          key={item.value}
          id={`nav-tooltip-${index}`}
        >
          <button
            onClick={() => setSelected(item.value)}
            className={`w-16 h-16 p-4 ${
              item.value === selected
                ? 'bg-light-surfaces-secondary dark:bg-dark-surfaces-secondary'
                : ''
            }`}
            aria-labelledby={`nav-tooltip-${index}`}
          >
            <span>{item.icon}</span>
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

export default function WrappedLeftNav<T extends string>(
  props: NavConfig<T>
): JSX.Element {
  return (
    <ErrorBoundary errorContext="Error in LeftNav">
      <LeftNav {...props} />
    </ErrorBoundary>
  );
}
