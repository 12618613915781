import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { useEffect, useState } from 'react';
import { dataverse } from '@/services/common/config';
import { getAccessToken } from '@/controllers/common/utils';

import DisplayValue from '../../components/DisplayValue';

interface WhoAmIResponse {
  '@odata.context': string;
  BusinessUnitId: string;
  UserId: string;
  OrganizationId: string;
}
export async function callCrmWhoAmI(accessToken: string): Promise<WhoAmIResponse | undefined> {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Accept', 'application/json');

  const options = {
    method: 'GET',
    headers: headers,
  };

  const response = await fetch(`${dataverse.baseApi}/WhoAmI`, options);
  if (response.ok) {
    const data = (await response.json()) as WhoAmIResponse;

    return data;
  }
}

const Page = () => {
  const { instance, inProgress } = useMsal();
  const account = instance.getActiveAccount() as AccountInfo;
  const [accessToken, setAccessToken] = useState('');
  const [crmData, setCrmData] = useState<WhoAmIResponse | undefined>();

  useEffect(() => {
    if (!accessToken) {
      getAccessToken(instance)
        .then((token) => setAccessToken(token))
        .catch((err) => console.error('failed to get access token', err));
    }
  }, [instance, accessToken, setAccessToken]);

  useEffect(() => {
    if (!crmData && accessToken) {
      callCrmWhoAmI(accessToken)
        .then((response) => setCrmData(response))
        .catch((err) => console.error('failed to fetch crm data', err));
    }
  }, [inProgress, crmData, accessToken]);

  return (
    <div id="profile-div">
      <DisplayValue label="Name" value={account.name} />
      <DisplayValue label="Username" value={account.username} />
      {crmData && (
        <>
          <br />
          <DisplayValue label="CRM" shouldBreak />
          <DisplayValue label="User Id" value={crmData.UserId} />
          <DisplayValue label="Business Unit Id" value={crmData.BusinessUnitId} />
          <DisplayValue label="Organization Id" value={crmData.OrganizationId} />
        </>
      )}
    </div>
  );
};

export default Page;
