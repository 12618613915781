import { dataverse } from '@/services/common/config';
import { request, RequestError } from '@/services/common/utils';
import {
  Opportunity,
  OpportunityResponse,
} from '@/models/document/opportunity';

export const getOpportunityList = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Opportunity[]> => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  };
  const params = {
    selectFields: 'opportunityid, _parentaccountid_value, name',
    filterFields: `_parentaccountid_value eq ${accountId}`,
  };

  const url = `${dataverse.baseApi}/opportunities?$select=${params.selectFields}&$filter=${params.filterFields}`;

  const response = await request(url, requestOptions, authedFetch);

  if (response instanceof RequestError) {
    console.log('Failed to load opportunities');
    return [];
  }
  const data = await response.json();

  return data.value.map((item: OpportunityResponse) => {
    return {
      opportunityId: item.opportunityid,
      name: item.name,
      parentAccountId: item._parentaccountid_value,
    } as Opportunity;
  });
};
