import { Card, Typography } from '@material-tailwind/react';
import { useMemo } from 'react';

type rowItem = string | number | boolean;
interface TableProps {
  headings: string[];
  rows: rowItem[][];
  removeEmptyColumns?: boolean;
}

export default function Table({
  headings,
  rows,
  removeEmptyColumns = true,
}: TableProps) {
  const emptyColumnIndexes: number[] = useMemo(() => {
    if (!removeEmptyColumns) {
      return [];
    }
    const emptyValues = [undefined, null, ''];
    const numColumns = rows[0].length;
    if (!rows.every((row) => row.length === numColumns)) {
      console.warn(
        'WARNING: Table component expects all rows to have the same length'
      );
    }
    const emptyColumns: number[] = [];
    for (let index = 0; index < numColumns; index++) {
      const allEmpty = rows.every((row) =>
        emptyValues.includes(row[index] as any)
      );
      if (allEmpty) {
        emptyColumns.push(index);
      }
    }
    return emptyColumns;
  }, [rows, removeEmptyColumns]);
  const filteredHeadings = headings.filter(
    (_, index) => !emptyColumnIndexes.includes(index)
  );
  const placeholder = '-';

  return (
    <Card className="h-full w-full overflow-scroll z-0 shadow-none">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {filteredHeadings.map((head) => (
              <th
                key={head}
                className="border-b border-light-borders-tertiary dark:border-dark-borders-tertiary p-4"
              >
                <Typography className="leading-none font-medium text-sm text-light-text-secondary dark:text-dark-text-secondary">
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, outerIndex) => {
            const isLast = outerIndex === rows.length - 1;
            const classes = isLast
              ? 'p-4'
              : 'p-4 border-b border-light-borders-tertiary dark:border-dark-borders-tertiary';
            return (
              <tr
                key={outerIndex}
                className={
                  outerIndex % 2 === 0 ? 'bg-light-surfaces-subtle' : ''
                }
              >
                {row.map((cell, innerIndex) => {
                  if (emptyColumnIndexes.includes(innerIndex)) {
                    return null;
                  }
                  return (
                    <td className={classes} key={innerIndex}>
                      <Typography className="font-medium text-sm text-light-text-contrast dark:text-dark-text-contrast">
                        {cell === '' ? placeholder : cell}
                      </Typography>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
}
