import { maxUploadSize } from '@/services/common/config';

export const validMimeTypes = [
  'application/msword',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/xml',
  'text/csv',
  'text/plain',
  'text/xml',
];

export const validateFile = (file: any) => {
  const fileType = file.type;
  if (
    !fileType.startsWith('image/') &&
    !fileType.startsWith('video/') &&
    !validMimeTypes.includes(fileType)
  ) {
    return {
      isInvalid: true,
      message:
        'Invalid file type. Supported types: image, video, doc, docx, pdf, xml, txt, csv.',
    };
  }

  if (file.size > maxUploadSize) {
    return {
      isInvalid: true,
      message: `File is too big. Max size is ${
        parseInt(maxUploadSize) / 1000 / 1000
      }Mb.`,
    };
  }

  return {
    isInvalid: false,
    message: '',
  };
};
