import { Submission } from '@/models/forms/submission';
import { APIForm } from '@bwinsurance/shared-components';
import { useState } from 'react';

interface Props {
  submissionData: Submission | undefined;
  form: APIForm | undefined;
  errorMessage?: string;
}

const SubmissionView: React.FC<Props> = ({
  submissionData,
  form,
  errorMessage,
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  return (
    <div>
      <h4>Submission</h4>
      {submissionData && <pre>{JSON.stringify(submissionData, null, 2)}</pre>}
      {errorMessage && <span>{errorMessage}</span>}

      {form && (
        <>
          <button onClick={() => setShowForm(!showForm)}>
            {showForm ? 'Hide' : 'Show'} form details
          </button>
          {showForm && <pre>{JSON.stringify(form, null, 2)}</pre>}
        </>
      )}
    </div>
  );
};

export default SubmissionView;
