import { useState, useEffect, useRef, FC } from 'react';
import { IntakeNavOptions } from '@/services/common/constants';
import SideNavItem, { NavBarItem } from '../SideNavItem';
import { Search } from './components/search';
import { SendToRaterButton } from '../forms/SendToRaterButton';

const navItems: NavBarItem[] = [
  {
    icon: <span className="material-symbols-outlined">people_outline</span>,
    value: IntakeNavOptions.APPLICANTS,
    label: 'Applicant(s)',
    form_page: 'applicants',
    search_count: null,
  },
  {
    label: 'Applicant',
    value: IntakeNavOptions.APPLICANT,
    form_page: 'applicant',
    search_count: null,
  },
  {
    label: 'Co-Applicant',
    value: IntakeNavOptions.COAPPLICANT,
    form_page: 'coApplicant',
    search_count: null,
  },
  {
    icon: <span className="material-symbols-outlined">directions_car</span>,
    value: IntakeNavOptions.AUTO,
    label: 'Vehicles',
    form_page: 'auto',
    search_count: null,
  },
  {
    icon: <span className="material-symbols-outlined">home</span>,
    value: IntakeNavOptions.HOME,
    label: 'Properties',
    form_page: 'property',
    search_count: null,
  },
];

type Field = {
  display: string;
  page: string;
};

export type SideNavProps = {
  selected: NavBarItem;
  setSelected: React.Dispatch<React.SetStateAction<NavBarItem>>;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => Promise<{ saveError: boolean; lastSaved: string | null }>;
  setSearchResults: React.Dispatch<React.SetStateAction<Field[] | null>>;
  isSaving: boolean;
  searchResults: Field[] | null;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  lastSaved: string | null;
  toggleRaterDialog: () => void;
};

const SideNav: FC<SideNavProps> = ({
  selected,
  setSelected,
  onSearch,
  onSave,
  setSearchResults,
  isSaving,
  searchResults,
  searchValue,
  setSearchValue,
  lastSaved,
  toggleRaterDialog,
}) => {
  const [forceUpdate, setForceUpdate] = useState(0);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const setCurrentPage = () => {
      const selectedNavItem =
        searchResults && searchResults?.length > 0
          ? navItems.find((item) => item.form_page === searchResults[0].page)
          : navItems[0];
      setSelected(selectedNavItem || navItems[0]);
    };

    const restoreOriginalLabels = () => {
      navItems.forEach((navItem) => (navItem.search_count = null));
    };

    const updatePageLabels = () => {
      if (!searchResults) {
        restoreOriginalLabels();
        return;
      }

      navItems.forEach((navItem) => {
        const resultCount =
          searchResults?.filter((result) => result.page === navItem.form_page)
            .length || null;
        navItem.search_count = resultCount || null;
      });
    };

    updatePageLabels();
    setCurrentPage();
    setForceUpdate((prev) => prev + 1);
  }, [searchResults, setSelected]);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, [forceUpdate]);

  return (
    <div
      data-testid="side-nav-container"
      className="fixed top-0 bottom-0 h-screen w-52 z-10 flex flex-col justify-between bg-[#F0F0F0] border-r border-r-[#E9E9E9]"
      key={forceUpdate}
    >
      <ul>
        <Search
          onSearch={onSearch}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchInputRef={searchInputRef}
        />
        {navItems.map((item, index) =>
          (searchResults && searchResults.length > 0 && item.search_count) ||
          !searchResults ? (
            <SideNavItem
              selected={selected}
              setSelected={setSelected}
              navItem={item}
              key={`${item.value}-${index}`}
            />
          ) : null
        )}
      </ul>
      <div className="flex flex-col items-center p-3 gap-3 border-t border-t-[#DDDDDD]">
        <div className="text-xs">
          {isSaving ? 'Saving...' : lastSaved ? `Last saved: ${lastSaved}` : ''}
        </div>
        <SendToRaterButton toggleRaterDialog={toggleRaterDialog} />
      </div>
    </div>
  );
};

export default SideNav;
