import { useEffect, useRef } from 'react';
import styles from './textField.module.css';
import '@/theme.module.css';
import Icon from '../Icon';
import { classNameSet } from '@/controllers/common/utils';

/**
 * Displays a styled text input field.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {string} config.label - The text to display above the input.
 * @param {string} config.value - The value of the input field.
 * @param {function} config.onChange - The function to handle changes in the input field.
 * @param {boolean} [config.readonly] - Flag indicating whether the field is editable.
 * @param {boolean} [config.required] - Flag indicating whether the field is required.
 * @param {boolean} [config.disabled] - Flag indicating whether the field is disabled.
 * @param {string} [config.placeholder] - Placeholder text.
 * @param {string} [config.classes] - Any additional tailwind css classes to apply to the input.
 * @param {string} [config.theme] - Indicates which style theme to apply: bw or crm.
 * @param {boolean} [config.error] - Flag indicating wheter the field is in an error state.
 * @param {string} [config.errorMessage] - Error text to display below the input.
 * @param {boolean} [config.loading] - Flag indicating wheter thhe field is in a loading state.
 *
 * @example
 * <TextField label={'Email address'}  value={emailAddress} onChange={(event) => setEmailAddress(event)} />
 */
export default function TextField({
  label,
  value,
  onChange,
  readonly = false,
  required = false,
  disabled = false,
  placeholder,
  classes,
  theme = 'bw',
  spellcheck,
  pattern,
  error = false,
  errorMessage,
  loading = false,
}: {
  label?: string;
  value: string;
  onChange: any;
  readonly?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  classes?: string;
  theme?: 'bw' | 'crm';
  spellcheck?: boolean | undefined;
  pattern?: string;
  error?: boolean;
  errorMessage?: string;
  loading?: boolean;
}) {
  const inputRef: { current: any } = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      setTimeout(() => inputRef.current && inputRef.current.select());
    }
  }, [inputRef]);

  return (
    <label
      className={classNameSet(
        styles[theme],
        error ? styles.error : undefined,
        classes
      )}
    >
      {label && <span className={styles.label}>{label}</span>}
      {required && <span className={styles.required}>*</span>}
      <input
        type="text"
        className={styles.input}
        onChange={(event) => onChange(event.target.value)}
        value={value}
        ref={inputRef}
        readOnly={readonly}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus
        spellCheck={spellcheck}
        pattern={pattern}
        data-testid={`${label || 'no-label'}-textfield`}
      />
      {error && (
        <div className={styles['icon']}>
          <div className={styles['icon-position']}>
            <Icon type={'warning'} color={'error'} />
          </div>
        </div>
      )}
      {loading && (
        <div className={styles['icon']}>
          <div
            className={classNameSet(styles['icon-position'], styles.spinner)}
          >
            <Icon type={'spinner'} color={'grey-300'} viewbox={'-4 -4 24 24'} />
          </div>
        </div>
      )}
      {error && errorMessage && (
        <div className={styles['error-text']}>
          <span>{errorMessage}</span>
        </div>
      )}
    </label>
  );
}
