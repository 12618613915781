import Documents from './documents';
import { useParams } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';

export default function Page({ pca }: { pca: IPublicClientApplication }) {
  const { id: accountId } = useParams();

  if (!accountId) {
    return;
  }

  return <Documents account={accountId.toLowerCase()} pca={pca} />;
}
