import styles from './sectionDivider.module.css';
import '../../theme.module.css';

/**
 * Displays a section separator
 *
 * @param {Object} config - The configuration object for this component.
 * @param {string} [config.title] - Text to display in the separator.
 * @param {string} [config.icon] - The icon to display in the separator.
 * @param {string} [config.theme] - Indicates which style theme to apply: bw or crm.
 *
 * @example
 * <SectionDivider title={'Properties'} icon={'description'} />
 */
export default function SectionDivider({
  title,
  icon,
  theme = 'bw',
}: {
  title: string;
  icon?: string;
  theme?: 'bw' | 'crm';
}) {
  return (
    <div className={[styles[theme], styles.container].join(' ')}>
      {icon && (
        <span className={`material-symbols-outlined ${styles.icon}`}>
          {icon}
        </span>
      )}
      <span className={styles.text}>{title}</span>
      <div className={styles.line}></div>
    </div>
  );
}
