import { applicant } from './applicant';
import { property } from './property';
import { auto } from './auto';
import { coApplicant } from './coApplicant';
import { applicants } from './applicants';
import { defaultValuesMap } from './schema';

export const agentIntake = {
  defaults: defaultValuesMap.agentIntakeDefaults,
  pages: { applicants, applicant, coApplicant, auto, property },
};
