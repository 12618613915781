export class RequestError extends Error {
  constructor(
    message: string,
    public status?: number,
    public statusText?: string
  ) {
    super(message);
  }

  toString() {
    return [this.status, this.statusText, this.message]
      .filter(Boolean)
      .join('; ')
      .replace(/; $/, '');
  }
}

export const request = async (
  url: RequestInfo | URL,
  requestOptions: RequestInit,
  fetchFn: typeof fetch = fetch
): Promise<Response | RequestError> => {
  try {
    const response = await fetchFn(url, requestOptions);
    if (!response.ok) {
      throw new RequestError(
        response.statusText,
        response.status,
        response.statusText
      );
    }
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    return new RequestError(err.message);
  }
};
