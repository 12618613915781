import { useState, useEffect, useCallback } from 'react';
import { IntakeNavOptions } from '@/services/common/constants';
import SideNav from '@/components/SideNav';
import Switch from '@/components/Switch';
import { NavBarItem } from '@/components/SideNavItem';
import type { AgentIntakeForm } from '@/services/forms/agent/schema';
import { agentIntake } from '@/services/forms/agent';
import {
  FormPage,
  ActionBanner,
  PrefillButton,
  Overlay,
} from '@/components/forms';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { defaultValuesMap } from '@/services/forms/agent/schema';
import type { DefaultValuesMap } from '@/services/forms/agent/schema';
import { useWindowConfirm } from '@/controllers/common/hooks/utility/useWindowConfirm';
import { useData } from '@/controllers/opportunity/hooks/useData';
import { flattenToEAV } from '@/services/opportunity/utils';
import { postEav } from '@/services/opportunity/network/eav';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { formSearch } from '@/services/opportunity/utils/formSearch';
import { SendToRaterDialog } from '@/components/forms/SendToRaterDialog';
import { useRaterIntegration } from '@/controllers/opportunity/hooks/useRaterIntegration';
import { useQuoteSubmissionForApplication } from '@/controllers/opportunity/hooks/useQuoteSubmissionForApp';
import { RaterProductEnabled } from '@/services/forms/models/rateProductTypes';
import { useSendAndGetQuoteGroups } from '@/controllers/opportunity/hooks/useSendAndGetQuoteGroups';
import {
  getSuccessMessage,
  getErrorMessage,
  getRaterButtonErrorMessage,
  getErrorMessages,
  ErrorType,
} from '@/services/forms/helpers/utils';
import { RatersEnum } from '@bwinsurance/meta-rater-types';
import { useMsal } from '@azure/msal-react';
import { dataverse } from '@/services/common/config';
import { getAccessToken, WhoAmIResponse } from '@/controllers/common/utils';
import { prefillData } from '@/services/opportunity/network/prefill';
import { populateAuto } from '../../services/forms/prefill/populateAuto';
import { populateProperty } from '../../services/forms/prefill/populateProperty';
import {
  showPrefillBanner,
  checkRequiredFieldsForPrefill,
  getMissingFieldsString,
  isPrefillButtonDisabled,
  getPrefillType,
  getPrefillToast,
} from '../../services/forms/prefill/prefillHelper';
import { useAlertToast } from '@/controllers/common/hooks/useAlertToast';
import { getAgent } from '@/services/opportunity/network/dynamics';

const OpportunityIntake = () => {
  const authedFetch = useAuthenticatedFetch();
  const { formData, loading, errorText, eavEntityId } = useData();
  const { raterProductEnabled } = useRaterIntegration({
    loading,
    crmAgentId: formData?.crm?.agentId,
  });
  const { successQuoteGroups, setSuccessQuoteGroups, successQRQuoteGroup } =
    useQuoteSubmissionForApplication({
      loading,
      applicationId: formData?.application?.id,
    });
  const { AlertToastList, addToast, removeAllToast } = useAlertToast();

  const [selectedTab, setSelectedTab] = useState<NavBarItem>({
    icon: <span className="material-symbols-outlined">people_outline</span>,
    value: IntakeNavOptions.APPLICANTS,
    label: 'Applicant(s)',
    search_count: null,
  });
  const currentTab = selectedTab.parent || selectedTab.value;
  const [isSaving, setIsSaving] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [raterDialogOpen, setRaterDialogOpen] = useState(false);
  const [sendingToRater, setSendingToRater] = useState(false);
  const [prefillingData, setPrefillingData] = useState(false);
  const [lastSaved, setLastSaved] = useState<string | null>(null);

  type Field = {
    display: string;
    page: string;
  };

  const [searchResults, setSearchResults] = useState<Field[] | null>(null);

  const formMethods = useForm<AgentIntakeForm>({
    defaultValues: agentIntake.defaults,
    mode: 'onBlur',
  });
  const driverFieldArray = useFieldArray({
    name: 'drivers',
    control: formMethods.control,
  });
  const vehicleFieldArray = useFieldArray({
    name: 'vehicles',
    control: formMethods.control,
  });

  const { isDirty, isValid, errors } = formMethods.formState;
  const { instance, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState('');
  const [crmData, setCrmData] = useState<WhoAmIResponse | undefined>();
  const [agentMismatchName, setAgentMismatchName] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!accessToken) {
      getAccessToken(instance)
        .then((token) => setAccessToken(token))
        .catch((err) => console.error('failed to get access token', err));
    }
  }, [instance, accessToken, setAccessToken]);

  useEffect(() => {
    if (!crmData && accessToken) {
      callCrmWhoAmI(accessToken)
        .then((response: any) => {
          setCrmData(response);
        })
        .catch((err: any) => console.error('failed to fetch crm data', err));
    }
  }, [inProgress, crmData, accessToken]);

  useEffect(() => {
    if (formData) {
      formMethods.reset(formData);
    }
  }, [formData, formMethods]);

  useEffect(() => {
    if (formData && crmData && formData.crm?.agentId) {
      if (formData.crm?.agentId !== crmData?.UserId) {
        getAgent(formData.crm?.agentId as string, accessToken)
          .then((response: any) => {
            setAgentMismatchName(response?.fullname);
          })
          .catch((err: any) =>
            console.error('failed to fetch agent data', err)
          );
      }
    }
  }, [formData, crmData, accessToken]);

  const callCrmWhoAmI = async (
    accessToken: string
  ): Promise<WhoAmIResponse | undefined> => {
    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    });

    const response = await fetch(`${dataverse.baseApi}/WhoAmI`, {
      method: 'GET',
      headers,
    });

    if (response.ok) {
      return response.json() as Promise<WhoAmIResponse>;
    }

    return undefined;
  };

  const onSubmit = (data: AgentIntakeForm) => {
    console.log(data);
  };

  const getLastSaved = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return formattedDate;
  };

  const onSave = useCallback(async () => {
    let saveError = false;
    let lastSaved = null;

    if (!isDirty && isValid) {
      addToast([{ type: 'success', text: 'Data saved' }]);
      lastSaved = getLastSaved();
      saveError = false;
      return { saveError, lastSaved };
    }

    if (!isValid || Object.keys(errors).length) {
      addToast([{ type: 'warning', text: getErrorMessage('InvalidFields') }]);
      saveError = true;
      return { saveError, lastSaved };
    }

    if (eavEntityId) {
      setIsSaving(true);

      try {
        const data = formMethods.getValues();
        const eav = flattenToEAV(data, eavEntityId);

        const result = await postEav({ body: eav, fetchFn: authedFetch });

        if (!result) {
          saveError = true;
          throw new Error('Failed to save EAV');
        }

        addToast([{ type: 'success', text: 'Data saved' }]);
        formMethods.reset(formMethods.getValues(), { keepValues: true });
        saveError = false;
        lastSaved = getLastSaved();
      } catch (err) {
        console.log(err);
        addToast([
          {
            type: 'warning',
            text: 'Something went wrong while saving data. Please try again!',
          },
        ]);
        saveError = true;
      }

      setIsSaving(false);
    }

    return { saveError, lastSaved };
  }, [isDirty, isValid, eavEntityId, formMethods, authedFetch]);

  useWindowConfirm(isDirty, onSave);

  useEffect(() => {
    const autoSave = async () => {
      if (isDirty && !isSaving) {
        try {
          const { lastSaved } = await onSave();
          setLastSaved(lastSaved);
        } catch (error) {
          console.error('Auto-save failed:', error);
        }
      }
    };

    const autoSaveInterval = setInterval(() => {
      autoSave();
    }, 60000); // 1 minute

    return () => clearInterval(autoSaveInterval);
  }, [isDirty, onSave]);

  useWindowConfirm(isDirty, onSave);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const results = value ? formSearch(value, agentIntake) : null;
    setSearchValue(value);
    setSearchResults(results);
  };

  const { sendAndGetQuoteGroups } = useSendAndGetQuoteGroups();

  const handleSendRater = async (raterProductToSend: RaterProductEnabled[]) => {
    const isSendingQuoteRushOnly =
      raterProductToSend.length === 1 &&
      raterProductToSend[0].rater === RatersEnum.enum.QuoteRush;
    if (successQRQuoteGroup && isSendingQuoteRushOnly) {
      const leadId = successQRQuoteGroup.raterData?.leadId;
      addToast([
        {
          type: 'warning',
          text: getErrorMessage('AlreadySentError', {
            leadId: leadId,
            quoteGroupNames: ['QuoteRUSH'],
          }),
        },
      ]);
      await toggleRaterDialog();
      return;
    }

    try {
      setSendingToRater(true);
      const { saveError } = await onSave();
      if (!saveError) {
        const toasts = [];
        if (!eavEntityId) {
          throw new Error('Missing entity id, unable to send to rater');
        }
        const { completedQuoteGroups, failedQuoteGroups } =
          await sendAndGetQuoteGroups(eavEntityId, raterProductToSend);

        if (completedQuoteGroups.length) {
          setSuccessQuoteGroups([
            ...completedQuoteGroups,
            ...(successQRQuoteGroup ? [successQRQuoteGroup] : []),
          ]);
          toasts.push({
            type: 'success',
            text: getSuccessMessage(completedQuoteGroups),
          });
        }

        if (failedQuoteGroups.length) {
          const messages = getErrorMessages(failedQuoteGroups);
          messages.forEach((message) => {
            toasts.push({
              type: 'warning',
              text: message,
            });
          });
        }
        addToast(toasts);
      }
    } catch (error) {
      console.log(error);
      const err = error as Error;
      const errorParams = { failureMessage: err.message };
      addToast([
        {
          type: 'warning',
          text: getErrorMessage(err.name as ErrorType, errorParams),
        },
      ]);
    }
    setSendingToRater(false);
    await toggleRaterDialog();
  };

  const toggleRaterDialog = async () => {
    if (!raterProductEnabled.length) {
      addToast([
        {
          type: 'warning',
          text: await getRaterButtonErrorMessage(
            accessToken,
            formData?.crm?.agentId,
            crmData?.UserId
          ),
        },
      ]);
      return;
    }
    setRaterDialogOpen(!raterDialogOpen);
  };

  const { isHomeTab, prefillType, prefillAddressType } =
    getPrefillType(currentTab);

  const prefillBannerMessage = `Save time by pre-filling key ${prefillType} information. Just ensure you've entered the ${prefillAddressType} address.`;

  const handlePrefill = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    removeAllToast();

    const missingFields = checkRequiredFieldsForPrefill(
      isHomeTab,
      formMethods.getValues
    );
    if (missingFields.length) {
      const missingFieldString = getMissingFieldsString(
        isHomeTab,
        missingFields
      );
      addToast([
        {
          type: 'warning',
          text: `To pre-fill data, please add the ${missingFieldString}`,
        },
      ]);
      return;
    }

    const currentFocus = document.activeElement;
    let prefillSuccessful = false;
    try {
      setPrefillingData(true);
      const result = await prefillData({
        prefillType: currentTab,
        data: formMethods.getValues(),
        userId: crmData?.UserId,
        fetchFn: authedFetch,
      });

      const toast = getPrefillToast(result, prefillType);
      addToast([toast]);

      if (toast.type === 'success') {
        if (!isHomeTab) {
          populateAuto(
            formMethods,
            result,
            driverFieldArray,
            vehicleFieldArray
          );
        }

        if (isHomeTab) {
          populateProperty(formMethods, result);
        }

        prefillSuccessful = true;
      }

      if (currentFocus instanceof HTMLElement) {
        currentFocus.focus();
      }
    } catch (error) {
      console.log('Error prefilling data', error);
      addToast([
        {
          type: 'warning',
          text: 'We\'re having trouble pre-filling data right now. Please contact Agent Support if this continues.',
          action: (
            <button className="underline" onClick={handlePrefill}>
              {'Try again'}
            </button>
          ),
        },
      ]);
    }
    setPrefillingData(false);

    // calling onSave at the end to ensure form updates are propagated
    if (prefillSuccessful) {
      onSave();
    }
  };

  const overlayMessage = sendingToRater
    ? 'Sending...'
    : prefillingData
    ? 'Pre-filling data...'
    : '';

  if (loading) {
    return (
      <div className="text-center text-xl mt-10">
        <span>Loading</span>
      </div>
    );
  }
  if (errorText) {
    return (
      <div className="text-center text-xl mt-10">
        <span>{errorText}</span>
      </div>
    );
  }

  return (
    <div className="border border-[#E9E9E9] gap-5 h-full">
      <Overlay
        showOverlay={sendingToRater || prefillingData}
        message={overlayMessage}
      />
      {AlertToastList}
      <SendToRaterDialog
        open={raterDialogOpen}
        handleSendRater={handleSendRater}
        toggleRaterDialog={toggleRaterDialog}
        raterProductEnabled={raterProductEnabled}
        successQRQuoteGroup={successQRQuoteGroup}
        agentMismatchName={agentMismatchName}
      />
      <FormProvider {...formMethods}>
        <SideNav
          selected={selectedTab}
          setSelected={setSelectedTab}
          onSave={onSave}
          onSearch={onSearch}
          isSaving={isSaving}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          lastSaved={lastSaved}
          toggleRaterDialog={toggleRaterDialog}
        />
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="flex flex-col ml-52 p-6 gap-8">
            <ActionBanner
              bannerMessage={prefillBannerMessage}
              bannerActions={
                <PrefillButton
                  handlePrefill={handlePrefill}
                  disableButton={isPrefillButtonDisabled(
                    isHomeTab,
                    formMethods.getValues()
                  )}
                  prefillType={prefillType}
                />
              }
              showBanner={showPrefillBanner(currentTab)}
            />
            <Switch selectedKey={currentTab}>
              <FormPage
                key={IntakeNavOptions.APPLICANTS}
                page={agentIntake.pages.applicants}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.APPLICANT}
                page={agentIntake.pages.applicant}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.COAPPLICANT}
                page={agentIntake.pages.coApplicant}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.AUTO}
                page={agentIntake.pages.auto}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
                formMethods={formMethods}
              />
              <FormPage
                key={IntakeNavOptions.HOME}
                page={agentIntake.pages.property}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
              />
            </Switch>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default OpportunityIntake;
