import { AgentIntakeForm } from '@/services/forms/agent/schema';
import {
  PrefillRequest,
  PrefillResponse,
  Request,
} from '@/services/forms/models/prefillTypes';

export const prefillData = async ({
  prefillType,
  data,
  userId = '',
  fetchFn,
}: {
  prefillType: string;
  data: AgentIntakeForm;
  userId?: string;
  fetchFn: typeof fetch;
}): Promise<PrefillResponse> => {
  let prefillRequest: Request;
  if (prefillType === 'auto') {
    const hasPreviousAddress =
      !!data.applicant?.previousAddress &&
      !!data.applicant.previousAddress.lineOne &&
      !!data.applicant.previousAddress.stateCode;
    prefillRequest = {
      target: 'auto',
      requestDetails: {
        applicant: {
          firstName: data.applicant?.firstName as string,
          lastName: data.applicant?.lastName as string,
          dateOfBirth: data.applicant?.dateOfBirth
            ? String(data.applicant?.dateOfBirth)
            : '',
          currentAddress: {
            lineOne: data.applicant?.currentAddress?.lineOne as string,
            lineTwo: data.applicant?.currentAddress?.lineTwo,
            city: data.applicant?.currentAddress?.city,
            stateCode: data.applicant?.currentAddress?.stateCode as string,
            postalCode: data.applicant?.currentAddress?.postalCode?.substring(
              0,
              5
            ),
          },
          ...(hasPreviousAddress
            ? {
                previousAddress: {
                  lineOne: data.applicant?.previousAddress?.lineOne as string,
                  lineTwo: data.applicant?.previousAddress?.lineTwo,
                  city: data.applicant?.previousAddress?.city,
                  stateCode: data.applicant?.previousAddress
                    ?.stateCode as string,
                  postalCode:
                    data.applicant?.previousAddress?.postalCode?.substring(
                      0,
                      5
                    ),
                },
              }
            : {}),
        },
      },
    };
  } else {
    prefillRequest = {
      target: 'property',
      requestDetails: {
        address: {
          lineOne: data.home?.address?.lineOne as string,
          lineTwo: data.home?.address?.lineTwo,
          city: data.home?.address?.city,
          stateCode: data.home?.address?.stateCode as string,
          postalCode: data.home?.address?.postalCode?.substring(0, 5),
        },
      },
    };
  }

  const body: PrefillRequest = {
    requestor: {
      userId,
      userSystem: 'dynamics',
      service: 'fusion-ui',
    },
    requests: [prefillRequest],
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'default',
    },
    body: JSON.stringify(body),
  };
  const url = 'apim/prefill/v1/api/data';
  const response = await fetchFn(url, requestOptions);

  if (!response.ok) {
    throw new Error('Failed to prefill data, please try again.');
  }

  return response.json();
};
