import { useState, type FC, useEffect } from 'react';
import {
  INSURANCE_PRODUCT_TO_CRM_PRODUCT,
  RaterProductEnabled,
  RaterSelectedMap,
} from '@/services/forms/models/rateProductTypes';
import { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import Dialog from '@/components/shared/dialog';
import { Button } from '@/components/ui/button';
import { Raters, RatersEnum } from '@bwinsurance/meta-rater-types';
import Checkbox from '@/components/shared/checkbox';

export type SendToRaterDialogProps = {
  open: boolean;
  handleSendRater: (raterProductToSend: RaterProductEnabled[]) => void;
  toggleRaterDialog: () => void;
  raterProductEnabled: RaterProductEnabled[];
  successQRQuoteGroup?: QuoteGroupWithQuote;
  agentMismatchName: string | undefined;
};

const SendToRaterDialog: FC<SendToRaterDialogProps> = ({
  open,
  handleSendRater,
  toggleRaterDialog,
  raterProductEnabled,
  successQRQuoteGroup,
  agentMismatchName,
}) => {
  const [selectedRaters, setSelectRaters] = useState<RaterSelectedMap>(
    {} as RaterSelectedMap
  );

  useEffect(() => {
    if (raterProductEnabled.length && open) {
      const selectedRaterMap = {} as RaterSelectedMap;
      raterProductEnabled.forEach(({ rater }) => {
        if (!selectedRaterMap[rater]) {
          selectedRaterMap[rater] =
            rater === RatersEnum.enum.QuoteRush ? !successQRQuoteGroup : true;
        }
      });
      setSelectRaters(selectedRaterMap);
    }
  }, [raterProductEnabled, open, successQRQuoteGroup]);

  if (!open || !raterProductEnabled.length) {
    return null;
  }

  const noneSelected = Object.values(selectedRaters).every(
    (checked) => !checked
  );

  const checkboxDisabled = (rater: Raters) =>
    rater === RatersEnum.enum.QuoteRush && !!successQRQuoteGroup;

  const toggleSelectRater = (rater: Raters) => {
    if (checkboxDisabled(rater)) {
      return;
    }
    setSelectRaters({
      ...selectedRaters,
      [rater]: !selectedRaters[rater],
    });
  };

  const onClickSend = () => {
    const ratersToSend: RaterProductEnabled[] = [];

    raterProductEnabled.forEach((raterProduct) => {
      if (selectedRaters[raterProduct.rater]) {
        ratersToSend.push(raterProduct);
      }
    });

    handleSendRater(ratersToSend);
  };

  const hasQuoteRush = raterProductEnabled.some(
    (raterProduct) => raterProduct.rater === RatersEnum.enum.QuoteRush
  );

  const consolidatedRaters = raterProductEnabled.reduce<
    { rater: Raters; products: INSURANCE_PRODUCT_TO_CRM_PRODUCT[] }[]
  >((acc, curr) => {
    const existingRater = acc.find((r) => r.rater === curr.rater);
    const convertedProduct = INSURANCE_PRODUCT_TO_CRM_PRODUCT[curr.product];

    if (existingRater) {
      if (!existingRater.products.includes(convertedProduct)) {
        existingRater.products.push(convertedProduct);
      }
    } else {
      acc.push({
        rater: curr.rater,
        products: [convertedProduct],
      });
    }

    return acc;
  }, []);

  const getRaterLabel = (rater: Raters) => {
    if (rater === RatersEnum.enum.QuoteRush) {
      return 'QuoteRUSH';
    }
    return rater;
  };

  return (
    <Dialog open={open}>
      <div
        className="flex justify-between items-center gap-2.5"
        data-testid={'send-to-rater-dialog-title'}
      >
        <h6 className="leading-[26.6px] font-semibold text-light-text-contrast dark:text-dark-text-contrast">
          Ready to send?
        </h6>
        <Button onClick={toggleRaterDialog} variant={'ghost'} size={'icon'}>
          <span className="material-symbols-outlined text-[20px] text-light-icons-secondary dark:text-dark-icons-secondary">
            close
          </span>
        </Button>
      </div>
      <div className="mt-3 gap-3 text-light-text-contrast dark:text-dark-text-contrast text-sm">
        <table className="w-full text-left table-auto mb-4">
          <thead>
            <tr>
              <td className="p-4 pt-3 pb-3 border-b border-t border-[#cad0db]">
                <strong>Rater(s)</strong>
              </td>
              <td className="p-4 pt-3 pb-3 border-b border-t border-[#cad0db]">
                <strong>Product(s)</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            {consolidatedRaters
              .sort((a, b) => a.rater.localeCompare(b.rater))
              .map((raterProduct) => (
                <tr key={raterProduct.rater}>
                  <td className="p-4 border-b border-[#cad0db]">
                    <Checkbox
                      label={getRaterLabel(raterProduct.rater)}
                      checked={!!selectedRaters[raterProduct.rater]}
                      disabled={checkboxDisabled(raterProduct.rater)}
                      onChange={() => toggleSelectRater(raterProduct.rater)}
                    />
                  </td>
                  <td className="p-4 border-b border-[#cad0db]">
                    {raterProduct.products.map((product, index) => (
                      <div key={index}>{product}</div>
                    ))}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {hasQuoteRush && successQRQuoteGroup ? (
          <p data-testid={'already-sent-to-QR-message'}>
            Data has been{' '}
            <span className="font-semibold">sent to QuoteRUSH already</span>.{' '}
            Please make further adjustments directly in QuoteRUSH.
          </p>
        ) : (
          <div className="flex flex-col gap-3">
            {hasQuoteRush && (
              <table className="w-full text-left table-auto">
                <tbody>
                  <tr>
                    <td className="align-top">
                      <span className="material-symbols-outlined text-sm pr-2">
                        info
                      </span>
                    </td>
                    <td>
                      <p data-testid={'send-to-QR-warning-message'}>
                        Data{' '}
                        <span className="font-semibold">
                          can only be sent to QuoteRUSH once
                        </span>
                        . You can make further adjustments directly in
                        QuoteRUSH.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
        <div className="flex flex-col gap-3">
          {agentMismatchName && (
            <table className="w-full text-left table-auto">
              <tbody>
                <tr>
                  <td className="align-top">
                    <span className="material-symbols-outlined text-sm pr-2">
                      info
                    </span>
                  </td>
                  <td>
                    <p data-testid={'agent-mismatch-warning-message'}>
                      {agentMismatchName} is currently assigned to this
                      opportunity. If you send now, this opportunity will show
                      up in their Rater account, not yours.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="flex justify-end gap-2.5 pt-2">
        <Button
          data-testid={'send-to-raters-button'}
          onClick={onClickSend}
          variant={'link'}
          className="py-1.5 px-3"
          disabled={noneSelected}
        >
          <span>Send</span>
        </Button>
        <Button onClick={toggleRaterDialog} variant={'outline'} size={'sm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export { SendToRaterDialog };
