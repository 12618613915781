import { useParams, useSearchParams } from 'react-router-dom';
import {
  Copy,
  Form,
  FormContent,
  APIManager,
  LoadingSpinner,
  useAsync,
} from '@bwinsurance/shared-components';
import '@bwinsurance/shared-components/dist/styles.css';
import { useCallback, useEffect, useState } from 'react';
import { CUSTOMER_ID_SEARCH_PARAM, POLICY_ID_SEARCH_PARAM } from './constants';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import Header from '../forms/components/header';
import styles from './PolicyFormPage.module.css';
import { featureFlagCompleteForm } from '@/services/common/config';
import { IPublicClientApplication } from '@azure/msal-browser';
import clientLogger from '../../controllers/logger';
import { useNavigate } from 'react-router-dom';

interface Props {
  pca: IPublicClientApplication;
}

const PolicyFormPage: React.FC<Props> = ({ pca }) => {
  const user = pca.getActiveAccount();
  const [urlSearchParams] = useSearchParams();
  const customerId = urlSearchParams.get(CUSTOMER_ID_SEARCH_PARAM);
  const policyId = urlSearchParams.get(POLICY_ID_SEARCH_PARAM);
  const { formKey } = useParams();
  const authedFetch = useAuthenticatedFetch();
  const submitterEmail = user?.username;
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  if (!submitterEmail) {
    clientLogger.error('Missing submitter email for account', { user });
    throw new Error('Missing submitter email');
  }

  if (!customerId) {
    clientLogger.error('Missing customerId');
    throw new Error('Missing customerId');
  }

  if (!formKey) {
    clientLogger.error('Missing form key', { user });
    throw new Error('Missing form key');
  }

  /**
   * Gets the form to be rendered to the user
   */
  const { data, state, triggerUpdate } = useAsync<FormContent, undefined>(
    useCallback(async () => {
      const result = await APIManager.forms.getCompleteForm(
        {
          customerId,
          policyId: policyId ?? undefined,
          formType: formKey,
          apimURL: '/apim',
          apimKey: '',
          submitterEmail,
        },
        { injectedFetch: authedFetch }
      );
      return result.formContent;
    }, [authedFetch, customerId, formKey, policyId, submitterEmail])
  );

  useEffect(() => void triggerUpdate(undefined), [triggerUpdate]);

  const submitForm = useCallback(
    async (formData: FormData) => {
      const response = await authedFetch('/apim/forms/v1/submissions', {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
      }
      setSubmitted(true);
    },
    [authedFetch]
  );

  return (
    <>
      <Header />
      <div className={styles.container}>
        {submitted ? (
          <Copy element="p">Form submitted successfully!</Copy>
        ) : state === 'complete' ? (
          <Form
            onAction={async (formAction, formData) => {
              let redirect = null;
              switch (formAction.actionType) {
                case 'submit': {
                  await submitForm(formData);
                  break;
                }
                case 'submit-and-redirect': {
                  await submitForm(formData);
                  redirect = formAction.redirectURL;
                  break;
                }
                case 'redirect': {
                  redirect = formAction.redirectURL;
                  break;
                }
              }
              if (redirect) {
                navigate(redirect);
              }
            }}
            options={{
              googlePlaces: {
                apiKey: featureFlagCompleteForm,
              },
            }}
            rows={data?.rows ?? []}
            actions={data?.actions ?? []}
          />
        ) : (
          <>
            <LoadingSpinner />
            <Copy element="p">Loading form...</Copy>
          </>
        )}
      </div>
    </>
  );
};

export default PolicyFormPage;
