import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Opportunity } from '@/models/document/opportunity';
import { Customer } from '@/models/document/customer';
import { Contact } from '@/models/document/contact';
import { Policy } from '@/models/document/policy';
import { Document } from '@/models/document/document';
import { getAccessToken } from '@/controllers/common/utils';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import {
  getAccountDetails,
  getAssociatedContactList,
  getCategoryList,
  getDocumentsForLinkId,
  getPolicyList,
} from '@/services/document/documentService';
import { getOpportunityList } from '@/services/document/dynamicsService';
import { getDynamicsOpportunityDetails } from '@/services/opportunity/network/dynamics';
import DocumentsForOpportunity from './documents';
import { TableTextInterface } from '@/components/shared/table';

const OpportunityDocuments = () => {
  const { id } = useParams();
  const oppId = id ? id.toLowerCase() : '';
  const { instance } = useMsal();
  const authedFetch = useAuthenticatedFetch();
  const [accessToken, setAccessToken] = useState('');
  const [accountId, setAccountId] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [associatedContactList, setAssociatedContactList] = useState<Contact[]>(
    []
  );

  const [errorText, setErrorText] = useState<string>();
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [opportunityList, setOpportunityList] = useState<Opportunity[]>([]);
  const [policyList, setPolicyList] = useState<Policy[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);

  const [tableText, setTableText] = useState<TableTextInterface>({
    text: '',
    subText: '',
  });

  const getAccountId = async (oppId: string, accessToken: string) => {
    try {
      const oppDetails = await getDynamicsOpportunityDetails({
        accessToken,
        opportunityId: oppId,
        queryParams: {
          selectFields: 'name,_parentaccountid_value',
          expandFields: '',
        },
      });

      if (!oppDetails) {
        throw new Error('Failed to fetch account id from dynamics.');
      }
      setAccountId(oppDetails._parentaccountid_value);
    } catch (error) {
      console.log('Failed to fetch account id from dynamics.');
    }
  };

  const refreshDocsData = useCallback(async () => {
    setDocumentsLoading(true);
    try {
      if (!oppId) {
        throw new Error('Missing opportunity id');
      }
      if (!accountId) {
        throw new Error('Missing account id');
      }
      const opportunityDocs = await getDocumentsForLinkId(oppId, authedFetch);
      if (!opportunityDocs) {
        throw new Error('Unable to load documents for Opportunity');
      }

      setDocuments(opportunityDocs);
    } catch (error) {
      console.log(error);
      setErrorText(
        'Failed to load documents for opportunity, please try again.'
      );
    }
    setDocumentsLoading(false);
  }, [accountId, authedFetch, oppId]);

  const loadSupportData = async (accountId: string) => {
    try {
      const opportunities = await getOpportunityList(accountId, authedFetch);
      setOpportunityList(opportunities);
      const categories = await getCategoryList(authedFetch);
      setCategoryList(categories);
      const newCustomer = await getAccountDetails(accountId, authedFetch);
      setCustomer(newCustomer);
      const newAssociatedContactList = await getAssociatedContactList(
        accountId,
        authedFetch
      );
      setAssociatedContactList(newAssociatedContactList);
      const newPolicies = await getPolicyList(accountId, authedFetch);
      setPolicyList(newPolicies);
    } catch (error) {
      console.log('Failed to load support data');
      setOpportunityList([]);
      setCategoryList([]);
    }
  };

  useEffect(() => {
    if (documentsLoading) {
      setTableText({
        text: 'Loading documents...',
        subText: '',
        showSpinner: true,
      });
    } else if (errorText) {
      setTableText({ text: `${errorText}`, subText: '' });
    } else if (documents.length) {
      setTableText({
        text: 'We couldn\'t find a matching document...',
        subText: '',
      });
    } else {
      setTableText({ text: 'Looks empty here...', subText: '' });
    }
  }, [documentsLoading, documents.length, setTableText, errorText]);

  useEffect(() => {
    if (!accessToken) {
      getAccessToken(instance)
        .then((token) => setAccessToken(token))
        .catch((err) => console.error('failed to get access token', err));
    }

    if (!userName) {
      setUserName(instance.getActiveAccount()?.name || '');
    }
  }, [instance, accessToken, setAccessToken, userName]);

  useEffect(() => {
    if (oppId && accountId) {
      refreshDocsData();
    }
  }, [oppId, accountId, authedFetch, refreshDocsData]);

  useEffect(() => {
    if (oppId && accessToken && !accountId) {
      getAccountId(oppId, accessToken);
    }
  }, [oppId, authedFetch, accessToken, accountId]);

  useEffect(() => {
    if (accountId) {
      loadSupportData(accountId);
    }
  }, [accountId, authedFetch]);

  return (
    <DocumentsForOpportunity
      documents={documents}
      setDocuments={setDocuments}
      userName={userName}
      customer={customer}
      contactList={associatedContactList}
      categoryList={categoryList}
      opportunityList={opportunityList}
      policyList={policyList}
      refreshData={refreshDocsData}
      accountId={accountId}
      opportunityId={oppId}
      tableText={tableText}
    />
  );
};

export default OpportunityDocuments;
