import {
  genderOptions,
  maritalStatusOptions,
  employmentStatusOptions,
  licenseStatusOptions,
  relationOptions,
  vehicleUsageOptions,
  vehicleOwnershipOptions,
  homeOwnershipOptions,
  booleanDropdownOptions,
  stateCodeOptions,
  educationOptions,
  monthsDropDownOptions,
  AutoPriorCarrierEnum,
} from '../helpers';
import type { FormPage } from '../models/formTypes';

export const auto: FormPage = {
  id: 'auto',
  display: 'Auto',
  icon: 'directions_car',
  actions: [
    {
      id: 'ezlynxLink',
      type: 'ezlynx',
      fieldName: 'auto',
    },
  ],
  elements: [
    {
      id: 'residence',
      display: 'Residence',
      type: 'section',
      elements: [
        {
          name: 'residence.ownership',
          display: 'Ownership status',
          placeholder: 'Select...',
          type: 'dropdown',
          options: homeOwnershipOptions,
        },
      ],
    },
    {
      id: 'drivers',
      icon: 'people_outline',
      display: 'Driver',
      type: 'dynamicPanel',
      defaults: 'driverDefaults',
      actions: [
        {
          id: 'applicant-sync',
          type: 'sync-fields',
          fieldName: '',
          syncFieldsOptions: {
            elementName: 'Driver 1',
            actionText: 'Populate driver details with applicant\'s data',
            icon: 'sparkle_filled',
            fieldMap: {
              'applicant.firstName': 'drivers.0.name.firstName',
              'applicant.lastName': 'drivers.0.name.lastName',
              'applicant.gender': 'drivers.0.gender',
              'applicant.dateOfBirth': 'drivers.0.dateOfBirth',
              'applicant.maritalStatus': 'drivers.0.maritalStatus',
              'applicant.education': 'drivers.0.education',
              'applicant.employmentStatus': 'drivers.0.employmentStatus',
              'applicant.industry': 'drivers.0.industry',
              'applicant.occupation': 'drivers.0.occupation',
            },
          },
        },
        {
          id: 'co-applicant-sync',
          type: 'sync-fields',
          fieldName: '',
          syncFieldsOptions: {
            elementName: 'Driver 2',
            actionText: 'Populate driver details with co-applicant\'s data',
            icon: 'sparkle_filled',
            fieldMap: {
              'coApplicant.firstName': 'drivers.{idx}.name.firstName',
              'coApplicant.lastName': 'drivers.{idx}.name.lastName',
              'coApplicant.gender': 'drivers.{idx}.gender',
              'coApplicant.dateOfBirth': 'drivers.{idx}.dateOfBirth',
              'coApplicant.maritalStatus': 'drivers.{idx}.maritalStatus',
              'coApplicant.education': 'drivers.{idx}.education',
              'coApplicant.employmentStatus': 'drivers.{idx}.employmentStatus',
              'coApplicant.industry': 'drivers.{idx}.industry',
              'coApplicant.occupation': 'drivers.{idx}.occupation',
              'coApplicant.relation': 'drivers.{idx}.relation',
            },
          },
        },
      ],
      elements: [
        {
          id: 'generalInfo',
          display: 'General Info',
          type: 'section',
          elements: [
            {
              name: 'name.firstName',
              display: 'First name',
              type: 'text',
              placeholder: 'Enter first name...',
              validation: {
                maxLength: {
                  value: 255,
                  message: 'First name cannot be more than 255 characters',
                },
              },
            },
            {
              name: 'name.lastName',
              display: 'Last name',
              type: 'text',
              placeholder: 'Enter last name...',
              validation: {
                maxLength: {
                  value: 255,
                  message: 'Last name cannot be more than 255 characters',
                },
              },
            },
            {
              name: 'gender',
              display: 'Gender',
              type: 'dropdown',
              placeholder: 'Select...',
              options: genderOptions,
            },
            {
              name: 'dateOfBirth',
              display: 'Date of birth',
              type: 'date',
            },
            {
              name: 'maritalStatus',
              display: 'Marital status',
              placeholder: 'Select...',
              type: 'dropdown',
              options: maritalStatusOptions,
            },
            {
              name: 'relation',
              display: 'Relation',
              placeholder: 'Select...',
              type: 'dropdown',
              options: relationOptions,
            },
            {
              name: 'education',
              display: 'Education',
              placeholder: 'Select...',
              type: 'dropdown',
              options: educationOptions,
            },
            {
              name: 'employmentStatus',
              display: 'Employment status',
              placeholder: 'Select...',
              type: 'dropdown',
              options: employmentStatusOptions,
            },
            {
              name: 'industry',
              display: 'Industry',
              visibleIf: {
                fieldName: 'employmentStatus',
                includeList: ['Employed'],
              },
              placeholder: 'Select...',
              type: 'apidropdown',
              optionsBy: 'dropdown/industry',
            },
            {
              name: 'occupation',
              display: 'Occupation',
              visibleIf: {
                fieldName: 'employmentStatus',
                includeList: ['Employed'],
              },
              placeholder: 'Select...',
              type: 'apidropdown',
              optionsBy: 'dropdown/occ_{industry}',
            },
          ],
        },
        {
          id: 'licenseDetails',
          display: 'Drivers License Details',
          type: 'section',
          elements: [
            {
              name: 'license.licenseNumber',
              display: 'License number',
              type: 'text',
              placeholder: 'Enter license number...',
            },
            {
              name: 'license.state',
              display: 'License state',
              placeholder: 'Select...',
              type: 'dropdown',
              options: stateCodeOptions,
            },
            {
              name: 'license.licensedDate',
              display: 'Date licensed',
              type: 'date',
            },
            {
              name: 'license.status',
              display: 'License status',
              placeholder: 'Select...',
              type: 'dropdown',
              options: licenseStatusOptions,
            },
            {
              name: 'hasReceivedViolation',
              display: 'Has a traffic violation',
              type: 'bool',
              options: booleanDropdownOptions,
            },
            {
              name: 'hasAccidentWithinLastFiveYears',
              display: 'Had an accident in last 5 years',
              type: 'bool',
              options: booleanDropdownOptions,
            },
            {
              name: 'hasLicenseRevokedSuspended',
              display: 'Has license been suspended',
              type: 'bool',
              options: booleanDropdownOptions,
            },
            {
              display: 'isDeleted',
              name: 'isDeleted',
              type: 'hidden',
            },
          ],
        },
      ],
    },
    {
      id: 'vehicles',
      icon: 'directions_car',
      display: 'Vehicle',
      type: 'dynamicPanel',
      defaults: 'vehicleDefaults',
      elements: [
        {
          id: 'generalInfo',
          display: 'General Info',
          type: 'section',
          elements: [
            {
              name: 'year',
              display: 'Year',
              type: 'apidropdown',
              placeholder: 'Select...',
              optionsBy: 'lookups/auto/years',
            },
            {
              name: 'make',
              display: 'Make',
              placeholder: 'Select...',
              type: 'apidropdown',
              optionsBy: 'lookups/auto/makes?year={year}',
            },
            {
              name: 'model',
              display: 'Model',
              placeholder: 'Select...',
              type: 'apidropdown',
              optionsBy: 'lookups/auto/models?year={year}&make={make}',
            },
            {
              name: 'subModel',
              display: 'Submodel',
              placeholder: 'Enter Submodel...',
              type: 'submodel',
            },
            {
              name: 'usage.ownership',
              display: 'Ownership',
              placeholder: 'Select...',
              type: 'dropdown',
              options: vehicleOwnershipOptions,
            },
            {
              name: 'vin',
              display: 'Vehicle identification number (VIN)',
              type: 'text',
              placeholder: 'Enter VIN...',
              validation: {
                minLength: {
                  value: 3,
                  message: 'VIN cannot be less than 3 digits',
                },
                maxLength: {
                  value: 17,
                  message: 'VIN cannot be more than 17 digits',
                },
              },
            },
            {
              name: 'usage.odometer',
              display: 'Odometer',
              type: 'number',
              placeholder: 'Enter odometer...',
            },
            {
              display: 'isDeleted',
              name: 'isDeleted',
              type: 'hidden',
            },
          ],
        },
        {
          id: 'usage',
          display: 'Usage',
          type: 'section',
          elements: [
            {
              name: 'usage.usage',
              display: 'Usage',
              placeholder: 'Select...',
              type: 'dropdown',
              options: vehicleUsageOptions,
            },
            {
              name: 'usage.principalDriverId',
              display: 'Primary driver',
              placeholder: 'Select...',
              type: 'dynamicdropdown',
              optionsBy: 'drivers',
            },
            {
              name: 'usage.oneWayMiles',
              display: 'One way miles',
              type: 'number',
              placeholder: 'Enter one way miles...',
            },
            {
              name: 'usage.daysPerWeek',
              display: 'Days per week',
              placeholder: 'Select...',
              type: 'dropdown',
              options: [
                { key: '1', display: '1' },
                { key: '2', display: '2' },
                { key: '3', display: '3' },
                { key: '4', display: '4' },
                { key: '5', display: '5' },
                { key: '6', display: '6' },
                { key: '7', display: '7' },
              ],
            },
            {
              name: 'usage.weeksPerMonth',
              display: 'Weeks per month',
              placeholder: 'Select...',
              type: 'dropdown',
              options: [
                { key: '1', display: '1' },
                { key: '2', display: '2' },
                { key: '3', display: '3' },
                { key: '4', display: '4' },
              ],
            },
            {
              name: 'usage.annualMiles',
              display: 'Annual miles',
              type: 'number',
              placeholder: 'Enter annual miles...',
            },
          ],
        },
      ],
    },
    {
      id: 'policy',
      display: 'Policy',
      type: 'section',
      elements: [
        {
          name: 'auto.policy.effectiveDate',
          display: 'Effective date',
          type: 'date',
        },
        {
          name: 'auto.policy.term',
          display: 'Term',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: '6 Month', display: '6 Month' },
            { key: '12 Month', display: '12 Month' },
          ],
        },
      ],
    },
    {
      // TODO: Adjust this sections duration dropdowns when data model issue is settled
      id: 'priorPolicy',
      display: 'Prior policy',
      type: 'section',
      elements: [
        {
          name: 'auto.priorPolicy.hasPriorPolicy',
          display: 'Has existing coverage/policy',
          type: 'dropdown',
          placeholder: 'Select...',
          options: [
            { key: 'hasCurrentPolicy', display: 'Has current policy' },
            { key: 'hasExpiredPolicy', display: 'Policy is expired' },
            { key: 'hasNeverInsured', display: 'Has no prior policy' },
          ],
        },
        {
          name: 'auto.priorPolicy.carrier',
          display: 'Carrier',
          placeholder: 'Select...',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
          type: 'dropdown',
          options: AutoPriorCarrierEnum,
        },
        {
          name: 'auto.priorPolicy.monthsWithCarrier',
          display: 'Years with carrier',
          placeholder: 'Select...',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
          type: 'dropdown',
          options: monthsDropDownOptions,
        },
        {
          name: 'auto.priorPolicy.expirationDate',
          display: 'Expiration date',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
          type: 'date',
        },
        // Questions for expired coverage
        {
          name: 'auto.priorPolicy.reasonForLapse',
          display: 'Reason for lapse',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasExpiredPolicy'],
          },
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Active Military Duty', display: 'Active Military Duty' },
            { key: 'Driving A Company Car', display: 'Driving A Company Car' },
            {
              key: 'Driving A Car Owned By A Relative',
              display: 'Driving A Car Owned By A Relative',
            },
            {
              key: 'Has Not Owned Or Operated A Vehicle',
              display: 'Has Not Owned Or Operated A Vehicle',
            },
            {
              key: 'Owned Veh Parked/Stored Off Road/Not Driven',
              display: 'Owned Veh Parked/Stored Off Road/Not Driven',
            },
            { key: 'Non-Payment', display: 'Non-Payment' },
            { key: 'Other', display: 'Other' },
          ],
        },
        {
          name: 'auto.priorPolicy.durationOfLapsedCoverage',
          display: 'Duration of lapse',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasExpiredPolicy'],
          },
          placeholder: 'Select...',
          type: 'dropdown',
          options: monthsDropDownOptions,
        },
        // Question for no previous policy
        {
          name: 'auto.priorPolicy.reasonNoPrior',
          display: 'Reason no prior',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasNeverInsured'],
          },
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Active Military Duty', display: 'Active Military Duty' },
            { key: 'Driving A Company Car', display: 'Driving A Company Car' },
            {
              key: 'Driving A Car Owned By A Relative',
              display: 'Driving A Car Owned By A Relative',
            },
            {
              key: 'Has Not Owned Or Operated A Vehicle',
              display: 'Has Not Owned Or Operated A Vehicle',
            },
            {
              key: 'Owned Veh Parked/Stored Off Road/Not Driven',
              display: 'Owned Veh Parked/Stored Off Road/Not Driven',
            },
            { key: 'Non-Payment', display: 'Non-Payment' },
            { key: 'Other', display: 'Other' },
          ],
        },
      ],
    },
  ],
};
