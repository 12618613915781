import React, { useEffect, useState, useCallback, type FC } from 'react';
import Dialog from '@/components/shared/dialog';
import { Button } from '@/components/ui/button';
import { useAuthenticatedFetch } from '../../../controllers/common/hooks/useAuthenticatedFetch';

type SubModelVehicle = {
  year: string | undefined;
  make: string | undefined;
  model: string | undefined;
  vehicle_number: number | undefined;
};

export type SubModelDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subModelVehicle: SubModelVehicle | undefined;
  setSubModelVehicle: React.Dispatch<
    React.SetStateAction<SubModelVehicle | undefined>
  >;
  assignSubModel: (
    vin: string,
    vehicle_number: number,
    submodel: string
  ) => void;
};

type SubModelItem = {
  key: string;
  display: string;
};

const SubModelDialog: FC<SubModelDialogProps> = ({
  open,
  setOpen,
  subModelVehicle,
  setSubModelVehicle,
  assignSubModel,
}) => {
  const [subModelData, setSubModelData] = useState<SubModelItem[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedSubModelKey, setSelectedSubModelKey] = useState<string | null>(
    null
  );
  const authedFetch = useAuthenticatedFetch();

  const fetchSubModelData = useCallback(async () => {
    if (
      subModelVehicle?.year &&
      subModelVehicle?.make &&
      subModelVehicle?.model
    ) {
      const { year, make, model } = subModelVehicle;
      const url = `apim/metarater/v1/lookups/auto/submodels?year=${encodeURIComponent(
        year
      )}&make=${encodeURIComponent(make)}&model=${encodeURIComponent(model)}`;

      try {
        setLoading(true);
        const response = await authedFetch(url);
        const data = await response.json();
        setSubModelData(data);
        setError(null);
      } catch (error) {
        setError('Failed to load Submodel data');
      } finally {
        setLoading(false);
      }
    }
  }, [authedFetch, subModelVehicle]);

  useEffect(() => {
    if (open) {
      fetchSubModelData();
    }
  }, [open, fetchSubModelData]);

  const handleClose = () => {
    setOpen(false);
    setSubModelVehicle(undefined);
    setSubModelData(null);
    setSelectedSubModelKey(null);
  };

  const handleRowClick = (key: string, vin: string, subModel: string) => {
    setSelectedSubModelKey(key);
    if (subModelVehicle) {
      assignSubModel(vin, subModelVehicle.vehicle_number ?? 0, subModel);
      handleClose();
    }
  };

  return (
    <Dialog open={open} width={800}>
      <div
        className="flex justify-between items-center gap-2.5"
        data-testid={'sub-model-dialog-title'}
      >
        <h6 className="leading-[26.6px] font-semibold text-light-text-contrast dark:text-dark-text-contrast">
          Select a Submodel
        </h6>
        <Button onClick={handleClose} variant={'ghost'} size={'icon'}>
          <span className="material-symbols-outlined text-[20px] text-light-icons-secondary dark:text-dark-icons-secondary">
            close
          </span>
        </Button>
      </div>

      <div className="mt-3 gap-3 text-light-text-contrast dark:text-dark-text-contrast text-sm">
        {loading ? (
          <p>Loading Submodel data...</p>
        ) : error ? (
          <p>{error}</p>
        ) : subModelData && subModelData.length > 0 ? (
          <>
            <p>
              <small>
                Select a Submodel to fill in the Submodel and VIN number fields.
              </small>
            </p>
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr className="border-b">
                  <th className="text-left p-2"></th>
                  <th className="text-left p-2">Submodel</th>
                  <th className="text-left p-2">Partial VIN</th>
                </tr>
              </thead>
              <tbody>
                {subModelData.map(({ key, display }) => {
                  const [subModel, vin] = display.split(' |');
                  return (
                    <tr
                      key={key}
                      className="border-b cursor-pointer hover:bg-gray-100 hover:rounded-md group"
                      onClick={() => handleRowClick(key, vin, subModel)}
                    >
                      <td className="p-2">
                        <input
                          className="appearance-none w-4 h-4 border border-300 rounded-full checked:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300 group-hover:bg-blue-100 group-hover:border-blue-500 group-hover:checked:bg-blue-500 mt-2"
                          type="radio"
                          name="selectedSubModel"
                          checked={selectedSubModelKey === key}
                          onChange={() => handleRowClick(key, vin, subModel)}
                        />
                      </td>
                      <td className="p-2">{subModel}</td>
                      <td className="p-2">{vin}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <p>No Submodel data available</p>
        )}
      </div>
    </Dialog>
  );
};

export { SubModelDialog };
