import { FusionForm } from '../../../../models/forms/form';
import FormListSection from './FormListSection';
import Checkbox from '@/components/shared/checkbox';
import { useFormContext } from '../form/FormContext';
import styles from './FormList.module.css';

interface Props {
  accountId: string;
}

const FormList: React.FC<Props> = ({ accountId }) => {
  const { toggleFormKey, selectedFormListKeys, forms } = useFormContext();

  return (
    <div className={styles['form-list']}>
      {forms.map((form: FusionForm) => {
        const hasFormPolicies = form.policies?.length
          ? form.policies.length > 0
          : false;
        if (hasFormPolicies) {
          return <FormListSection form={form} accountId={accountId} />;
        } else {
          //individual form
          const formIsSelected = selectedFormListKeys.includes(form.key);

          return (
            <div className={styles.form}>
              <Checkbox
                checked={formIsSelected}
                onChange={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  toggleFormKey(form.key);
                }}
                label={form.name}
                theme={'crm'}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export default FormList;
