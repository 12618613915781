import { ReactNode } from 'react';
import { CoverageDetails as Coverage } from '@bwinsurance/meta-rater-types';
import SubheadingDivider from '../SubheadingDivider';
import Table from '../Table';
import { formatUSD, formatPercentage } from '@/controllers/common/utils';

const CoverageDetailsTable = ({
  coverages,
}: {
  coverages: Coverage[];
}): JSX.Element => {
  const headings = [
    'Type',
    'Premium',
    'Deductible',
    'Deductible percent',
    'Per person limit',
    'Per person unlimited',
    'Per incident limit',
    'Per incident limit percent',
    'Per incident unlimited',
  ];
  const coverageKeys = [
    'displayName',
    'premium',
    'deductible',
    'deductiblePercent',
    'perPersonLimit',
    'perPersonUnlimited',
    'perIncidentLimit',
    'perIncidentLimitPercent',
    'perIncidentUnlimited',
  ];
  const getFormattedValue = (coverage: Coverage, key: string) => {
    let value = coverage[key as keyof Coverage];
    const currencyKeys = [
      'premium',
      'deductible',
      'perPersonLimit',
      'perIncidentLimit',
    ];
    const percentageKeys = ['deductiblePercent', 'perIncidentLimitPercent'];
    if (currencyKeys.includes(key)) {
      value = formatUSD(value as number);
    }
    if (percentageKeys.includes(key)) {
      value = formatPercentage(value);
    }
    if (value === false) {
      return 'No';
    }
    if (value === true) {
      return 'Yes';
    }
    return value ?? '';
  };
  const coverageStrings = coverages.map((coverage) =>
    coverageKeys.map((key) => getFormattedValue(coverage, key)).flat()
  );
  return <Table headings={headings} rows={coverageStrings} />;
};

const CoverageDetailsList = ({
  coverageDetailsList,
}: {
  coverageDetailsList?: Array<Coverage | null> | null;
}): ReactNode => {
  if (
    !Array.isArray(coverageDetailsList) ||
    !coverageDetailsList?.some(Boolean)
  ) {
    return null;
  }
  const validCoverages = coverageDetailsList.filter(Boolean);
  return (
    <SubheadingDivider headingText="Coverages">
      <CoverageDetailsTable coverages={validCoverages as Coverage[]} />
    </SubheadingDivider>
  );
};

export default CoverageDetailsList;
