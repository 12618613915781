import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../utils';

export const useAuthenticatedFetch = () => {
  const { instance } = useMsal();

  const getAuthenticatedFetchFn = useCallback(
    async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
      const accessToken = await getAccessToken(instance);
      const authHeaders = {
        Authorization: `Bearer ${accessToken}`,
      };

      const initWithAuth = {
        ...init,
        headers: {
          ...init?.headers,
          ...authHeaders,
        },
      };

      return fetch(input, initWithAuth);
    },
    [instance]
  );

  return getAuthenticatedFetchFn;
};
