import type { FormApplicant, FormCoApplicant } from './applicants';
import type { FormAuto, FormDriver, FormVehicle } from './auto';
import type { FormHome } from './property';

import { vehicleDefaults, driverDefaults } from './auto';
import { FormResidence, PrefillStatus } from './helpers';

export interface AgentIntakeForm {
  hasClaimHistory?: boolean;
  isMultiPolicy?: boolean;
  insuranceProducts?: string;

  crm?: {
    accountId?: string;
    agentId?: string;
    agencyId?: string;
  };
  application?: {
    id?: string;
    applicantId: string;
  };
  applicant?: FormApplicant;
  coApplicant?: FormCoApplicant;
  auto?: FormAuto;
  home?: FormHome;
  drivers: FormDriver[];
  residence?: FormResidence;
  vehicles: FormVehicle[];
  prefillStatus?: PrefillStatus;
}

export type { FormVehicle, FormDriver, FormAuto } from './auto';
export type { FormHome } from './property';
export type { FormApplicant } from './applicants';

export const defaultValuesMap = {
  agentIntakeDefaults: {
    vehicles: [{ ...vehicleDefaults }],
    drivers: [{ ...driverDefaults }],
  },
  vehicles: vehicleDefaults,
  drivers: driverDefaults,
};

export type DefaultValuesMap =
  (typeof defaultValuesMap)[keyof typeof defaultValuesMap];
