import clientLogger from '../logger';

export interface CustomerData {
  customerNumber?: string;
  amsUrl?: string;
}

export const getIntakeData = async (
  id: string,
  fetchFn: typeof fetch
): Promise<any> => {
  const response = await fetchFn(`/apim/metarater/v1/Intake/${id}`);
  if (response.status === 404) {
    return { error: 'No Intake Data Available' };
  }
  if (!response.ok) {
    return { error: 'Failed to load intake data, please try again.' };
  }
  return response.json();
};

export const getCustomerData = async (
  customerId: string,
  fetchFn: typeof fetch
): Promise<CustomerData | undefined> => {
  try {
    const response = await fetchFn(`/apim/customer/v1/customers/${customerId}`);
    if (response.status === 404) {
      throw new Error('No Customer data found');
    }
    if (!response.ok) {
      clientLogger.error(
        'Failed to load customer data for intake applicant, unexpected error occured',
        { status: response.status, message: await response.text(), customerId }
      );
      throw new Error('Failed to load Customer data, please try again.');
    }
    return response.json();
  } catch (error) {
    return undefined;
  }
};
