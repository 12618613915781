import Header from './components/header';
import { useParams } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import Submission from './components/SubmissionController';

export default function Page({ pca }: { pca: IPublicClientApplication }) {
  const { id: submissionId } = useParams();

  if (!submissionId) {
    return;
  }

  return (
    <>
      <Header />
      <Submission submissionId={submissionId} />
    </>
  );
}
