import { Applicant, Application } from '@bwinsurance/meta-rater-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOpportunityData } from '@/controllers/common/opportunity';
import { ApplicantDisplay } from '../../components/Applicant';
import { ApplicationDisplay } from '../../components/Application';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';

const ViewOpportunity = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>();
  const [applicant, setApplicant] = useState<Applicant>();
  const [application, setApplication] = useState<Application>();
  const { id } = useParams();
  const authedFetch = useAuthenticatedFetch();

  useEffect(() => {
    if (id) {
      const loadData = async (id: string) => {
        setLoading(true);
        try {
          const application = await getOpportunityData(id, authedFetch);
          if (application.error) {
            setErrorText(application.error);
          } else {
            setApplicant(application.applicant);
            setApplication(application);
          }
        } catch (error) {
          console.log(error);
          setErrorText('Failed to load opportunity data, please try again.');
        }
        setLoading(false);
      };
      loadData(id);
    }
  }, [id, authedFetch]);

  if (loading) {
    return (
      <div className="text-center text-xl mt-10">
        <span>Loading</span>
      </div>
    );
  }
  if (errorText) {
    return (
      <div className="text-center text-xl mt-10">
        <span>{errorText}</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <span className="font-bold text-lg">Intake Data</span>
      <ApplicantDisplay applicant={applicant} />
      <ApplicationDisplay application={application} />
    </div>
  );
};

export default ViewOpportunity;
