import { useMsal } from '@azure/msal-react';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { dataverse } from '@/services/common/config';
import { getAccessToken } from '@/controllers/common/utils';
import DisplayValue from '../../components/DisplayValue';

const ResourceList = () => {
  const { resourceType } = useParams();

  return (
    <div>
      <DisplayValue label="Resource Type" value={resourceType} />
      <div className="my-3">
        <EntityDefinition resourceType={resourceType || ''} />
      </div>
    </div>
  );
};

export default ResourceList;

type EntityDefinition = Record<string, unknown>;
export async function callCrmEntityDef(
  resourceType: string,
  accessToken: string
): Promise<EntityDefinition | undefined> {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Accept', 'application/json');

  const options = {
    method: 'GET',
    headers: headers,
  };

  const response = await fetch(
    `${dataverse.baseApi}/EntityDefinitions(LogicalName='${resourceType}')`,
    options
  );
  if (response.ok) {
    const data = (await response.json()) as EntityDefinition;

    return data;
  }
}

const EntityDefinition = ({ resourceType }: { resourceType: string }) => {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState('');
  const [data, setData] = useState<EntityDefinition | undefined>();

  useEffect(() => {
    if (!accessToken) {
      getAccessToken(instance)
        .then((token) => setAccessToken(token))
        .catch((err) => console.error('failed to get access token', err));
    }
  }, [instance, accessToken, setAccessToken]);

  useEffect(() => {
    if ((!data || data.LogicalName !== resourceType) && accessToken) {
      callCrmEntityDef(resourceType, accessToken)
        .then((response) => setData(response))
        .catch((err) => console.error('failed to fetch crm data', err));
    }
  }, [data, accessToken, resourceType]);

  if (data) {
    return (
      <>
        <DisplayValue label="Entity Definition" shouldBreak />
        <DisplayValue label="LogicalName" value={data.LogicalName as string} />
        <DisplayValue label="PrimaryNameAttribute" value={data.PrimaryNameAttribute as string} />
        <DisplayValue label="PrimaryIdAttribute" value={data.PrimaryIdAttribute as string} />
        <DisplayValue label="SchemaName" value={data.SchemaName as string} />
        <DisplayValue label="LogicalCollectionName" value={data.LogicalCollectionName as string} />
        <DisplayValue label="HasNotes" value={data.HasNotes ? 'true' : 'false'} />
        <DisplayValue label="HasActivities" value={data.HasActivities ? 'true' : 'false'} />
        <DisplayValue label="TableType" value={data.TableType as string} />
        <DisplayValue
          label="Description"
          value={
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (data.Description as any)?.LocalizedLabels?.[0]?.Label as string
          }
        />
        <DisplayValue
          label="DisplayCollectionName"
          value={
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (data.DisplayCollectionName as any)?.LocalizedLabels?.[0]?.Label as string
          }
        />
        <DisplayValue
          label="DisplayName"
          value={
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (data.DisplayName as any)?.LocalizedLabels?.[0]?.Label as string
          }
        />
        <DisplayValue
          label="Privileges"
          value={
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (data.Privileges as Array<any>).map((p) => p.Name).join(', ')
          }
        />
      </>
    );
  }

  return null;
};
