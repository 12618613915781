export default function Header() {
  return (
    <header className="bg-light-surfaces-contrast dark:bg-dark-surfaces-primary w-full py-[25px] px-[20px] flex justify-left items-center">
      <img
        src="/img/fusion_forms_logo_yellow.svg"
        alt="Fusion Forms"
        height="28"
      />
    </header>
  );
}
