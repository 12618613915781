import { useEffect, type FC } from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FormField } from '@/services/forms/models/formTypes';
import { useFormState, get, FieldError, useFormContext } from 'react-hook-form';

export type InputFieldProps = {
  field: FormField;
};

const InputField: FC<InputFieldProps> = ({ field }) => {
  const { getValues, register, setValue, trigger, watch } = useFormContext();
  const { id, name, display, placeholder, syncWith, disabled, highlight } =
    field;
  const { errors, isValid } = useFormState();
  const error: FieldError = get(errors, name);
  const val = watch(name);

  useEffect(() => {
    if (!isValid && field.validation?.required) {
      trigger(name);
    }
  }, [val]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value || undefined, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    if (syncWith) {
      setValue(syncWith.fieldName, e.target.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <div className="flex flex-col min-w-[236px] max-w-[492px] gap-1.5">
      <Label htmlFor={id || name}>
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{display}</span>
      </Label>
      <Input
        {...register(name, {
          ...(field.validation ? { ...field.validation } : {}),
        })}
        type={'text'}
        id={id || name}
        placeholder={placeholder}
        data-testid={id || name}
        disabled={disabled}
        onChange={handleChange}
      />
      {error && (
        <p className="text-sm text-light-text-error">{error.message}</p>
      )}
    </div>
  );
};

export { InputField };
