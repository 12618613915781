import { Applicant, Application, InsuranceProductEnum } from '@bwinsurance/meta-rater-types';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getCustomerData,
  getIntakeData,
  CustomerData,
} from '@/controllers/common/intake';
import ApplicantDisplay from '@/components/Applicant';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { IntakeNavOptions } from '@/services/common/constants';
import IntakeLeftNav from '@/components/IntakeLeftNav';
import Switch from '@/components/Switch';
import IntakeHeader from '@/components/IntakeHeader';
import HomeInfo from '@/components/HomeInfo';
import AutoInfo from '@/components/AutoInfo';
import PriorPolicyInfo from '@/components/PriorPolicyInfo';
import { useTitle } from '@/controllers/common/hooks/useTitle';

const Intake = () => {
  useTitle('Intake Info - Brightway');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>();
  const [applicant, setApplicant] = useState<Applicant>();
  const [applications, setApplications] = useState<Application[]>();
  const [selectedTab, setSelectedTab] = useState<IntakeNavOptions>(
    IntakeNavOptions.APPLICANT
  );
  const [customerData, setCustomerData] = useState<CustomerData>();
  const { id } = useParams();
  const authedFetch = useAuthenticatedFetch();

  useEffect(() => {
    if (id) {
      const loadData = async (id: string) => {
        setLoading(true);
        try {
          const intake = await getIntakeData(id, authedFetch);
          console.log('intake', intake);
          if (intake.error) {
            setErrorText(intake.error);
          } else {
            setApplicant(intake.applicant);
            setApplications(intake.applications);
            const customerData = await getCustomerData(
              intake.applicant.crmAccountId,
              authedFetch
            );
            setCustomerData(customerData);
          }
        } catch (error) {
          console.log(error);
          setErrorText('Failed to load opportunity data, please try again.');
        }
        setLoading(false);
      };
      loadData(id);
    }
  }, [id, authedFetch]);

  const homeApplication = applications?.find(
    (application) =>
      application.insuranceProduct === InsuranceProductEnum.enum.Home
  );
  const autoApplication = applications?.find(
    (application) =>
      application.insuranceProduct === InsuranceProductEnum.enum.Auto
  );

  const hasExistingPolicies = applications?.some((app) =>
    Boolean(app.priorPolicy)
  );

  const hiddenNavItems = useMemo(() => {
    const hidden: IntakeNavOptions[] = [];
    if (!homeApplication) {
      hidden.push(IntakeNavOptions.HOME);
    }
    if (!autoApplication) {
      hidden.push(IntakeNavOptions.AUTO);
    }
    if (!hasExistingPolicies) {
      hidden.push(IntakeNavOptions.POLICY);
    }
    return hidden;
  }, [homeApplication, autoApplication, hasExistingPolicies]);

  if (loading) {
    return (
      <div className="text-center text-xl mt-10">
        <span>Loading</span>
      </div>
    );
  }
  if (errorText) {
    return (
      <div className="text-center text-xl mt-10">
        <span>{errorText}</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <IntakeHeader
        applications={applications}
        applicant={applicant}
        customerData={customerData}
      />
      <div className="flex flex-row gap-4 overflow-y-auto">
        <IntakeLeftNav
          selected={selectedTab}
          setSelected={setSelectedTab}
          hiddenItems={hiddenNavItems}
        />
        <div className="w-full">
          <div className="flex flex-col gap-8 ps-22 py-6 pr-6">
            <Switch selectedKey={selectedTab}>
              <ApplicantDisplay
                key={IntakeNavOptions.APPLICANT}
                applicant={applicant}
                longform
              />
              <HomeInfo
                key={IntakeNavOptions.HOME}
                application={homeApplication}
              />
              <AutoInfo
                key={IntakeNavOptions.AUTO}
                application={autoApplication}
              />
              <PriorPolicyInfo
                key={IntakeNavOptions.POLICY}
                applications={applications}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intake;
