import { ReactNode, useEffect, useRef, useState } from 'react';
import { formatUTCDate, formatUSD } from '@/controllers/common/utils';
import Tooltip from '../shared/tooltip';
import { FieldTypes } from '@/services/forms/models/formTypes';

type Field = {
  display: string;
  page: string;
};

interface DisplayValueProps {
  label: string;
  value?: string | boolean | Date | number | null;
  shouldBreak?: boolean;
  defaultValue?: string;
  isDate?: boolean;
  isCurrency?: boolean;
  fieldType?: FieldTypes;
  searchResults?: Field[] | null;
}

const DisplayValue = ({
  label,
  value,
  shouldBreak = true,
  defaultValue = '',
  isDate = false,
  isCurrency = false,
  fieldType,
  searchResults,
}: DisplayValueProps): JSX.Element => {
  if (value === true) {
    value = 'Yes';
  }
  if (value === false) {
    value = 'No';
  }
  if (isDate) {
    value = formatUTCDate(value);
  }
  if (isCurrency) {
    value = formatUSD(value);
  }
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const displayText = value?.toString() || defaultValue;

  useEffect(() => {
    if (inputRef.current) {
      setIsTruncated(inputRef.current.scrollWidth > inputRef.current.clientWidth);
    }
  }, []);

  const highlight = searchResults?.some((result: Field) => label === result.display) || null;

  return (
    <div
      className={`flex flex-col justify-start ${
        !shouldBreak && 'sm:flex-row'
      } items-start gap-1.5 w-fit max-w-[492px] min-w-[236px]`}
    >
      <span className="text-sm text-light-text-secondary dark:text-dark-text-secondary">
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{label}</span>
      </span>
      <Tooltip message={displayText} hidden={!isTruncated}>
        <span
          className={`text-sm py-1.5 px-3 ${
            fieldType === 'readonly' ? 'bg-[#F5F5F5]' : 'bg-light-surfaces-subtle'
          } rounded-[4px] text-light-text-contrast dark:text-dark-text-contrast flex-none w-fit max-w-[492px] min-w-[236px] min-h-8 truncate`}
          ref={inputRef}
        >
          {displayText}
        </span>
      </Tooltip>
    </div>
  );
};

export const DisplayValueOptional = ({
  label,
  value,
  shouldBreak = true,
  isDate,
  isCurrency,
}: DisplayValueProps): ReactNode => {
  if (value === undefined || value === null || value === '') {
    return null;
  }
  return (
    <DisplayValue
      label={label}
      value={value}
      shouldBreak={shouldBreak}
      isDate={isDate}
      isCurrency={isCurrency}
    />
  );
};

export default DisplayValue;
